import i18next from "i18next";
import dayjs from "dayjs";
import { message, Space, Tag, Tooltip } from "antd";
import { CONSTANTS } from "@bcspi/common";
import { CSSProperties } from "react";
import { Instance } from "ui/Types";
import { BellOutlined, SyncOutlined } from "@ant-design/icons";
import AppLinks from "../ServiceInstances/AppLinks";
import { OperationVariables } from "@apollo/client";

export const getBooleanValueText = (value: boolean): string => {
  return value ? i18next.t("true") : i18next.t("false");
};

export const getBooleanValueTextYN = (value: boolean): string => {
  return value ? i18next.t("yes") : i18next.t("no");
};

export const getBooleanStringValueTextYN = (value: string): string => {
  return value.toLowerCase() === "true" ? i18next.t("yes") : i18next.t("no");
};

export const getBooleanStringValueText = (value: string): string => {
  return value.toLowerCase() === "true"
    ? i18next.t("true")
    : i18next.t("false");
};

export const getNumericValue = (value: number): string | number => {
  return value > 1000000000000
    ? dayjs.unix(value / 1000).format(i18next.t("date-format"))
    : String(value);
};

export const getNumericStringValue = (valueS: string): string | number => {
  const value = parseInt(valueS);
  return value > 1000000000000
    ? dayjs.unix(value / 1000).format(i18next.t("date-format"))
    : String(value);
};

export const formatDate = (value: dayjs.Dayjs): string => {
  return value.format(i18next.t("date-format"));
};

export const getArrayType = (arr: unknown[]): string | null => {
  return Array.length === 0 ? null : typeof arr[0];
};

export const isHtml = (str: string): boolean => {
  return /<\/?[a-z][\s\S]*>/i.test(str);
};

export const camelCaseToTitleCase = (in_camelCaseString: string): string => {
  if (
    !in_camelCaseString ||
    in_camelCaseString === null ||
    in_camelCaseString.length === 0
  )
    return "";
  const result = in_camelCaseString // "__ToGetYourGEDInTimeASongAboutThe26ABCsIsOfTheEssenceButAPersonalIDCardForUser_456InRoom26AContainingABC26TimesIsNotAsEasyAs123ForC3POOrR2D2Or2R2D"
    .replace(/(_)+/g, " ") // " ToGetYourGEDInTimeASongAboutThe26ABCsIsOfTheEssenceButAPersonalIDCardForUser 456InRoom26AContainingABC26TimesIsNotAsEasyAs123ForC3POOrR2D2Or2R2D"
    .replace(/([a-z])([A-Z][a-z])/g, "$1 $2") // " To Get YourGEDIn TimeASong About The26ABCs IsOf The Essence ButAPersonalIDCard For User456In Room26AContainingABC26Times IsNot AsEasy As123ForC3POOrR2D2Or2R2D"
    .replace(/([A-Z][a-z])([A-Z])/g, "$1 $2") // " To Get YourGEDIn TimeASong About The26ABCs Is Of The Essence ButAPersonalIDCard For User456In Room26AContainingABC26Times Is Not As Easy As123ForC3POOr R2D2Or2R2D"
    .replace(/([a-z])([A-Z]+[a-z])/g, "$1 $2") // " To Get Your GEDIn Time ASong About The26ABCs Is Of The Essence But APersonal IDCard For User456In Room26AContainingABC26Times Is Not As Easy As123ForC3POOr R2D2Or2R2D"
    .replace(/([A-Z]+)([A-Z][a-z][a-z])/g, "$1 $2") // " To Get Your GEDIn Time A Song About The26ABCs Is Of The Essence But A Personal ID Card For User456In Room26A ContainingABC26Times Is Not As Easy As123ForC3POOr R2D2Or2R2D"
    .replace(/([a-z]+)([A-Z0-9]+)/g, "$1 $2") // " To Get Your GEDIn Time A Song About The 26ABCs Is Of The Essence But A Personal ID Card For User 456In Room 26A Containing ABC26Times Is Not As Easy As 123For C3POOr R2D2Or 2R2D"

    // Note: the next regex includes a special case to exclude plurals of acronyms, e.g. "ABCs"
    .replace(/([A-Z]+)([A-Z][a-rt-z][a-z]*)/g, "$1 $2") // " To Get Your GED In Time A Song About The 26ABCs Is Of The Essence But A Personal ID Card For User 456In Room 26A Containing ABC26Times Is Not As Easy As 123For C3PO Or R2D2Or 2R2D"
    .replace(/([0-9])([A-Z][a-z]+)/g, "$1 $2") // " To Get Your GED In Time A Song About The 26ABCs Is Of The Essence But A Personal ID Card For User 456In Room 26A Containing ABC 26Times Is Not As Easy As 123For C3PO Or R2D2Or 2R2D"

    // Note: the next two regexes use {2,} instead of + to add space on phrases like Room26A and 26ABCs but not on phrases like R2D2 and C3PO"
    .replace(/([A-Z]{2,})([0-9]{2,})/g, "$1 $2") // " To Get Your GED In Time A Song About The 26ABCs Is Of The Essence But A Personal ID Card For User 456 In Room 26A Containing ABC 26 Times Is Not As Easy As 123 For C3PO Or R2D2 Or 2R2D"
    .replace(/([0-9]{2,})([A-Z]{2,})/g, "$1 $2") // " To Get Your GED In Time A Song About The 26 ABCs Is Of The Essence But A Personal ID Card For User 456 In Room 26A Containing ABC 26 Times Is Not As Easy As 123 For C3PO Or R2D2 Or 2R2D"
    .trim(); // "To Get Your GED In Time A Song About The 26 ABCs Is Of The Essence But A Personal ID Card For User 456 In Room 26A Containing ABC 26 Times Is Not As Easy As 123 For C3PO Or R2D2 Or 2R2D"

  // capitalize the first letter
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export const removeKeys = (
  obj: Record<string, unknown> | Record<string, unknown>[],
  key: string[],
): Record<string, unknown> | Record<string, unknown>[] =>
  obj !== Object(obj)
    ? obj
    : Array.isArray(obj)
      ? obj.map((item) => removeKeys(item, key))
      : Object.keys(obj)
          .filter((k) => !key.includes(k))
          .reduce(
            (acc, x) =>
              Object.assign(acc, {
                [x]: removeKeys(
                  obj[x] as Record<string, unknown> | Record<string, unknown>[],
                  key,
                ),
              }),
            {},
          );

export const truncate = (input: string, length: number): string =>
  input && input.length > length ? `${input.substring(0, length)}...` : input;

export const newlineText = (text: string): JSX.Element[] =>
  text.split("\n").map((str, idx) => <p key={idx}>{str}</p>);

// export const buildESId = (
//   envId: string,
//   itemId: string,
//   itemVersion: string,
//   latest: boolean
// ): string => `${envId}_${itemId}${latest ? "" : `_${itemVersion}`}`;

export const setWithExpiry = (key: string, value: Object, ttl: number) => {
  const now = new Date();

  // `item` is an object which contains the original value
  // as well as the time when it's supposed to expire
  const item = {
    value: value,
    expiry: now.getTime() + ttl,
  };
  localStorage.setItem(key, JSON.stringify(item));
};

export const getWithExpiry = (key: string): Object | null => {
  const itemStr = localStorage.getItem(key);

  // if the item doesn't exist, return null
  if (!itemStr) {
    return null;
  }

  const item = JSON.parse(itemStr);
  const now = new Date();

  // compare the expiry time of the item with the current time
  if (now.getTime() > item.expiry) {
    // If the item is expired, delete the item from storage
    // and return null
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
};

export const logError = (err: Error, params?: Record<string, unknown>) =>
  console.error(
    JSON.stringify({
      message: err.message,
      stack: err.stack,
      ...(params || {}),
    }),
  );

export const sessionExpired = () => {
  message.warning(i18next.t("your-session-has-expired") as string);
  window.location.replace(`${CONSTANTS.URL_ROOT}`);
  window.history.pushState(
    { state: "session-expired" },
    "",
    CONSTANTS.URL_ROOT,
  );
};

export const getLogoColour = (pluginId: string): string => {
  return CONSTANTS.PLUGIN_COLOURS[pluginId] ?? CONSTANTS.COLOURS.MARSHLAND_FADE;
};

export const getLogoText = (pluginId: string): string => {
  return CONSTANTS.PLUGIN_TEXT[pluginId] ?? pluginId;
};

export const getLogoIconCss = (
  pluginId: string,
  size: number,
): CSSProperties => {
  return {
    width: `${size}px`,
    height: `${size}px`,
    background: getLogoColour(pluginId),
    borderRadius: "5px",
  };
};

export const getStatusIconCss = (
  status: boolean,
  size: number,
): CSSProperties => {
  return {
    width: `${size}px`,
    height: `${size}px`,
    background: status ? CONSTANTS.COLOURS.BG_GREEN : CONSTANTS.COLOURS.BG_RED,
    borderRadius: "5px",
  };
};

const getLabel = (instance: Instance) => {
  switch (instance.buildResult) {
    case CONSTANTS.JENKINS_BUILD_RESULT.ABORTED:
    case CONSTANTS.JENKINS_BUILD_RESULT.FAILURE:
      if (instance.status === CONSTANTS.BUILD_STATE.DELETING)
        return {
          text: i18next.t("deleting"),
          color: "orange",
          icon: <SyncOutlined spin />,
        };
      return {
        text: i18next.t("error"),
        color: "red",
        // icon: <ExclamationCircleOutlined />,
      };
    default:
      switch (instance.status) {
        case CONSTANTS.BUILD_STATE.QUEUED:
          return {
            text: i18next.t("queued"),
            color: "orange",
            // icon: <ClockCircleOutlined />,
          };
        case CONSTANTS.BUILD_STATE.BUILDING:
          return {
            text: i18next.t("building"),
            color: "blue",
            icon: <SyncOutlined spin />,
          };
        case CONSTANTS.BUILD_STATE.COMPLETED:
          return instance.appInfo.some((a) => !a.status)
            ? {
                text: i18next.t("warning"),
                color: "red",
                // icon: <CloseCircleOutlined />,
                tip: i18next.t("check-apps"),
              }
            : {
                text: i18next.t("running"),
                color: "green",
                // icon: <PlayCircleOutlined />,
              };
        case CONSTANTS.BUILD_STATE.DELETING:
          return {
            text: i18next.t("deleting"),
            color: "orange",
            icon: <SyncOutlined spin />,
          };
        default:
          return instance.appInfo.some((a) => !a.status)
            ? {
                text: i18next.t("warning"),
                color: "red",
                // icon: <CloseCircleOutlined />,
                tip: i18next.t("check-apps"),
              }
            : {
                text: i18next.t("running"),
                color: "green",
                // icon: <PlayCircleOutlined />,
              };
      }
  }
};

export const getInstanceStatus = (
  instance: Instance,
  toggleNotifications: ((variables: OperationVariables) => void) | null,
) => {
  const { color, icon, text, tip } = getLabel(instance);
  return {
    text: text,
    bgColour:
      color === "red"
        ? CONSTANTS.COLOURS.BG_RED
        : color === "orange"
          ? CONSTANTS.COLOURS.BG_AMBER
          : CONSTANTS.COLOURS.BG_GREEN,
    jsx: (
      <Space>
        {toggleNotifications && (
          <Tooltip
            key="notify"
            title={
              instance.isWatched
                ? (i18next.t("notifications-on") as string)
                : (i18next.t("notifications-off") as string)
            }
          >
            <Tag
              style={{ cursor: "pointer" }}
              color={instance.isWatched ? "green" : ""}
              onClick={() =>
                toggleNotifications({ variables: { _id: instance._id } })
              }
            >
              <Space>
                <BellOutlined />
                <div>
                  {instance.isWatched ? i18next.t("on") : i18next.t("off")}
                </div>
              </Space>
            </Tag>
          </Tooltip>
        )}
        <Tooltip
          key="status"
          title={
            tip
              ? tip
              : instance.buildProgress === 100
                ? (instance.buildResult &&
                  instance.buildResult ===
                    CONSTANTS.JENKINS_BUILD_RESULT.FAILURE
                    ? `${instance.buildResult} - ${instance.requestCallbackResult}`
                    : instance.buildResult) ?? instance.status
                : null
          }
        >
          <Tag key={text} color={color} icon={icon}>
            {text}
          </Tag>
        </Tooltip>
      </Space>
    ),
  };
};

export const getAppLinks = (instance: Instance) => {
  if (instance.status === CONSTANTS.BUILD_STATE.DELETING) return <></>;
  else if (instance.status !== CONSTANTS.BUILD_STATE.COMPLETED)
    return (
      <div style={{ width: "100%", textAlign: "end" }}>
        {i18next.t("links-pending-placeholder")}
      </div>
    );
  else if (instance.buildResult !== CONSTANTS.JENKINS_BUILD_RESULT.SUCCESS)
    return <></>;
  return <AppLinks instance={instance} />;
};
