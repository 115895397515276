import {
  CloudServerOutlined,
  DashboardOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useMutation, useQuery } from "@apollo/client";
import { CONSTANTS, SECURITY } from "@bcspi/common";
import { Button, Empty, Input, Modal, Space, Tabs } from "antd";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { SystemContext } from "ui/App";
import {
  ALL_INSTANCES,
  ALL_TENANTS,
  ALL_USERS,
  DELETE_INSTANCE,
  TOGGLE_NOTIFICATIONS,
  USER_QUERY,
} from "ui/Queries";
import {
  DeleteInstanceVars,
  GetInstancesData,
  GetInstancesVars,
  Instance,
  Tenant,
} from "ui/Types";
import LoadingSpinner from "../Common/LoadingSpinner";
import { logError } from "../Utils";
import InstanceCard from "./InstanceCard";
import NewInstanceModal from "./NewInstanceModal";
import TenantCard from "./TenantCard";

const { confirm } = Modal;

type ViewProps = {
  noticeBanner: boolean;
};

const View = ({ noticeBanner }: ViewProps) => {
  const systemContext = useContext(SystemContext);
  const { t } = useTranslation();

  const [nameFilter, setNameFilter] = useState<undefined | string>();

  const [instancesModalVisible, setInstancesModalVisible] = useState(false);

  const { data, loading } = useQuery<GetInstancesData, GetInstancesVars>(
    ALL_INSTANCES,
    {
      variables: {
        nameFilter,
      },
      pollInterval: 5000,
    },
  );

  const { data: _d } = useQuery(USER_QUERY, {
    pollInterval: 5000,
  });

  const refetchQueries = [
    {
      query: ALL_INSTANCES,
      variables: {
        nameFilter,
      },
    },
    { query: ALL_USERS },
    { query: USER_QUERY },
  ];

  const [toggleNotifications] = useMutation(TOGGLE_NOTIFICATIONS, {
    refetchQueries,
  });

  const { data: dataTenants } = useQuery(ALL_TENANTS, {
    pollInterval: 5000,
    //skip: !systemContext.user?.roles.includes(SECURITY.USER_ROLES.SUPER_USER),
  });

  const [deleteInstance] = useMutation<
    { deleteInstance: Instance },
    DeleteInstanceVars
  >(DELETE_INSTANCE, { refetchQueries });

  const onDeleteInstance = (instance: Instance) => {
    confirm({
      title: t("remove"),
      content: t("remove-this-instance-question"),
      async onOk() {
        try {
          await deleteInstance({
            variables: {
              _id: instance._id,
            },
          });
        } catch (err) {
          logError(err as Error);
        }
      },
    });
  };

  const getPanels = () => {
    if (dataTenants && dataTenants.allTenants.length > 0) {
      return (
        <Space direction="vertical">
          {dataTenants.allTenants
            .filter((t: Tenant) => t.name !== CONSTANTS.DEFAULT_TENANT)
            .map((t: Tenant) => (
              <TenantCard
                key={t._id}
                tenant={t}
                instances={
                  data && data.getServiceInstances.instances.length > 0
                    ? data.getServiceInstances.instances.filter(
                        (i) => i.tenant._id === t._id,
                      )
                    : []
                }
              />
            ))}
        </Space>
      );
    } else if (loading) return <LoadingSpinner />;
    else return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  };

  return (
    <div style={{ marginBottom: "8px" }}>
      <Tabs
        // defaultActiveKey={selectionState.selected.action}
        // destroyInactiveTabPane
        items={[
          {
            key: "summary",
            label: (
              <Space>
                <DashboardOutlined />
                {t("summary")}
              </Space>
            ),
            children: (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: `calc(100vh - ${noticeBanner ? 214 : 174}px)`, //40? + 64 + 8 + 50 + 36 + 8 + 8
                  overflowY: "auto",
                  overflowX: "hidden",
                }}
              >
                {getPanels()}
              </div>
            ),
          },
          {
            key: "detail",
            label: (
              <Space>
                <CloudServerOutlined />
                {`${t("instances")}`}
              </Space>
            ),
            children: (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginBottom: "8px",
                  }}
                >
                  <Input
                    value={nameFilter}
                    style={{ width: 200 }}
                    placeholder={t("filter-placeholder")}
                    allowClear
                    onChange={(e) => setNameFilter(e.target.value)}
                  />
                  <Button
                    danger
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={() => setInstancesModalVisible(true)}
                    hidden={systemContext.user?.roles.includes(
                      SECURITY.USER_ROLES.SUPER_USER,
                    )}
                    disabled={
                      (data?.getServiceInstances.instances
                        .filter((i) => i.createdBy === systemContext.user?._id)
                        .reduce(
                          (prev, cur) => prev + cur.licenseAllocation,
                          0,
                        ) ?? 0) >=
                        (systemContext.user?.profile.licenseAllocation ?? 0) ||
                      (data?.getServiceInstances.instances.filter(
                        (i) => i.createdBy === systemContext.user?._id,
                      ).length ?? 0) >=
                        (systemContext.user?.profile.instanceAllocation ?? 0)
                    }
                  >
                    {t("create-instance")}
                  </Button>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: `calc(100vh - ${noticeBanner ? 250 : 210}px)`, //40? + 64 + 8 + 50 + 36 + 8 + 28 + 8 + 8
                    overflow: "auto",
                  }}
                >
                  {data && data.getServiceInstances.instances.length > 0 ? (
                    data.getServiceInstances.instances.map((i) => (
                      <InstanceCard
                        key={i._id}
                        instance={i}
                        toggleNotifications={toggleNotifications}
                        onDeleteInstance={onDeleteInstance}
                      />
                    ))
                  ) : loading ? (
                    <LoadingSpinner />
                  ) : (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  )}
                </div>
                <NewInstanceModal
                  visible={instancesModalVisible}
                  onComplete={() => setInstancesModalVisible(false)}
                  onCancel={() => setInstancesModalVisible(false)}
                />
              </>
            ),
          },
        ]}
      ></Tabs>
    </div>
  );
};

export default View;
