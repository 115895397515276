import { useState } from "react";
import { Card, Alert, Typography, Tag, message } from "antd";
import { Formik } from "formik";
import { Form, Input, SubmitButton } from "formik-antd";
import * as Yup from "yup";
import { CONSTANTS } from "@bcspi/common";
import { useTranslation } from "react-i18next";
import {
  ALL_TENANTS,
  ALL_USERS,
  SAVE_TENANT,
  TENANT_EXISTS,
  TENANT_QUERY,
  USER_QUERY,
} from "ui/Queries";
import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { Tenant } from "ui/Types";

const { Text } = Typography;

type TenantFormProps = {
  tenant: Tenant;
};

const TenantForm = ({ tenant }: TenantFormProps) => {
  const { t } = useTranslation();

  const [name, setName] = useState("");

  const { data } = useQuery(TENANT_EXISTS, {
    variables: { name, _id: tenant._id },
  });

  const Schema = Yup.object({
    name: Yup.string()
      .label(t("name"))
      .required(t("name-is-required"))
      .min(3)
      .max(59)
      .test(
        "name",
        t("this-name-has-already-been-used"),
        () => !data?.tenantExists ?? true,
      ),
    licenseAllocation: Yup.number()
      .label(t("license-allocation"))
      .required(t("license-allocation-is-required"))
      .min(tenant.licensesAllocated ?? 0),
    instanceAllocation: Yup.number()
      .label(t("instance-allocation"))
      .required(t("instance-allocation-is-required"))
      .min(tenant.instancesAllocated ?? 0),
    // supportEmail: Yup.string().email(),
  });

  const [saveTenant, { loading }] = useMutation(SAVE_TENANT, {
    refetchQueries: [
      { query: TENANT_QUERY, variables: { _id: tenant._id } },
      { query: ALL_TENANTS },
      { query: ALL_USERS },
      { query: USER_QUERY },
    ],
  });

  const [errors, setErrors] = useState<String[]>([]);

  return (
    <Formik
      initialValues={{
        name: tenant.name,
        licenseAllocation: tenant.licenseAllocation,
        instanceAllocation: tenant.instanceAllocation,
        supportEmail: tenant.supportEmail ?? null,
      }}
      validationSchema={Schema}
      enableReinitialize={true}
      onSubmit={async (d) => {
        try {
          setErrors([]);
          await saveTenant({ variables: { ...d, _id: tenant._id } });
          message.success(t("tenant-has-been-updated"));
        } catch (error) {
          if (error && (error as ApolloError).graphQLErrors) {
            setErrors(
              (error as ApolloError).graphQLErrors.map(
                ({ message: msg }) => msg,
              ),
            );
          }
        }
      }}
    >
      {(formik) => (
        <Form>
          <div style={{}}>
            <Form.Item name="name" style={{ width: 400 }}>
              <Text strong>{t("tenant-name")}</Text>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Input
                  style={{ marginTop: 4 }}
                  // prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                  name="name"
                  placeholder={t("tenant-name-placeholder")}
                  onChange={(e) => setName(e.target.value)}
                  disabled
                />
                {tenant.name === CONSTANTS.DEFAULT_TENANT && (
                  <div>
                    <Tag style={{ marginLeft: 8 }} color="green">
                      system
                    </Tag>
                  </div>
                )}
              </div>
            </Form.Item>

            <Form.Item name="licenseAllocation" style={{ width: 400 }}>
              <Text strong>{`${t("license-allocation-heading")} (min: ${
                tenant.licensesAllocated ?? 0
              })`}</Text>
              <Input
                style={{}}
                type={"number"}
                min={tenant.licensesAllocated ?? 0}
                name="licenseAllocation"
                placeholder={t("license-allocation-placeholder")}
              />
            </Form.Item>

            <Form.Item name="instanceAllocation" style={{ width: 400 }}>
              <Text strong>{`${t("instance-allocation-heading")} (min: ${
                tenant.instancesAllocated ?? 0
              })`}</Text>
              <Input
                style={{}}
                type={"number"}
                min={tenant.instancesAllocated ?? 0}
                name="instanceAllocation"
                placeholder={t("instance-allocation-placeholder")}
              />
            </Form.Item>

            <Form.Item name="supportEmail" style={{ width: 400 }}>
              <Text strong>{t("support-email")}</Text>
              <Input
                // prefix={
                //   <MedicineBoxOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                // }
                // type="email"
                name="supportEmail"
                placeholder={t("support-email-placeholder")}
              />
            </Form.Item>
          </div>
          <SubmitButton
            loading={loading}
            disabled={
              formik.isSubmitting ||
              (formik.values.name === tenant.name &&
                formik.values.licenseAllocation === tenant.licenseAllocation &&
                formik.values.instanceAllocation ===
                  tenant.instanceAllocation &&
                formik.values.supportEmail === tenant.supportEmail) ||
              !formik.isValid
            }
            style={{ marginTop: 12 }}
          >
            {t("save")}
          </SubmitButton>
          <div>
            {errors.map((msg, i) => (
              <Alert key={i} message={msg} type="error" showIcon />
            ))}
          </div>
        </Form>
      )}
    </Formik>
  );
};

type ViewProps = {
  tenantId: string | undefined;
};

const View = ({ tenantId }: ViewProps) => {
  const { t } = useTranslation();

  const { data, loading } = useQuery(TENANT_QUERY, {
    variables: { _id: tenantId },
    skip: !tenantId,
  });

  if (!tenantId || loading) {
    return (
      <div>
        <div>{t("select-a-tenant")}</div>
      </div>
    );
  }

  return data && data.getTenant ? (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "stretch",
        alignItems: "stretch",
      }}
    >
      <div style={{ padding: 12 }}>
        <Card size="small">
          <TenantForm tenant={data.getTenant} />
        </Card>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default View;
