import { SECURITY } from "@bcspi/common";
import { Space, Tooltip, Typography } from "antd";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { SystemContext } from "ui/App";

const { Text } = Typography;

const ResourceUsage: React.FC = () => {
  const { t } = useTranslation();
  const systemContext = useContext(SystemContext);

  return (
    <Space size={20}>
      {!systemContext.user?.roles.includes(SECURITY.USER_ROLES.SUPER_USER) && (
        <>
          <Tooltip title={t("used-allocation")}>
            <Text>{`${t("individual-licenses-prompt")} ${
              systemContext.user?.profile.resourceUsage.licensesUsed ?? 0
            }/${systemContext.user?.profile.licenseAllocation ?? 0}`}</Text>
          </Tooltip>
          <Tooltip title={t("used-allocation")}>
            <Text>{`${t("individual-instances-prompt")} ${
              systemContext.user?.profile.resourceUsage.instancesUsed ?? 0
            }/${systemContext.user?.profile.instanceAllocation ?? 0}`}</Text>
          </Tooltip>
        </>
      )}
      {(systemContext.user?.roles.includes(SECURITY.USER_ROLES.SUPER_USER) ||
        systemContext.user?.roles.includes(SECURITY.USER_ROLES.SPI_ADMIN)) && (
        <>
          <Tooltip title={t("used-allocated-allocation")}>
            <Text>{`${t("total-licenses-prompt")} ${
              systemContext.user?.profile.resourceUsage.totalLicensesUsed ?? 0
            }/${
              systemContext.user?.profile.resourceUsage
                .tenantLicensesAllocated ?? 0
            }/${
              systemContext.user?.profile.resourceUsage
                .totalLicenseAllocation ?? 0
            }`}</Text>
          </Tooltip>
          <Tooltip title={t("used-allocated-allocation")}>
            <Text>{`${t("total-instances-prompt")} ${
              systemContext.user?.profile.resourceUsage.totalInstancesUsed ?? 0
            }/${
              systemContext.user?.profile.resourceUsage
                .tenantInstancesAllocated ?? 0
            }/${
              systemContext.user?.profile.resourceUsage
                .totalInstanceAllocation ?? 0
            }`}</Text>
          </Tooltip>
        </>
      )}
    </Space>
  );
};

export default ResourceUsage;
