import { CONSTANTS } from "@bcspi/common";
import i18next from "i18next";

export const TAG_COLOUR: string = CONSTANTS.COLOURS.LIGHT_TEAL; //"blue";

export const LOCAL_STORAGE_KEYS = {
  STORE_VERSION: "store_version",
};

export const LOCAL_STORAGE_VERSION = "1.5.2.0";

export const ELLIPSIS_TEXT_MAX_LENGTH = 100;

export const ELLIPSIS_TAB_TEXT_MAX_LENGTH = 60;

export const ELLIPSIS_CHECK_TEXT_MAX_LENGTH = 40;

export const MAX_RECENT_ITEMS = 100;

export const COMPARE_COLOUR_ADD = "#cdffd8";
export const COMPARE_COLOUR_EDIT = "#ffeddb";
export const COMPARE_COLOUR_REMOVE = "#ffdce0";
export const COMPARE_COLOUR_SECTION = "#b3ecff";

export const PASSWORD_RULES = () => [
  {
    text: i18next.t("minimum-length-is-8-characters"),
    f: (password: string): boolean => password.length >= 8,
  },
  {
    text: i18next.t("password-must-contain-at-least-one-uppercase-character"),
    f: (password: string): boolean => /^.*((?=.*[A-Z]){1}).*$/.test(password),
  },
  {
    text: i18next.t("password-must-contain-at-least-one-lowercase-character"),
    f: (password: string): boolean => /^.*((?=.*[a-z]){1}).*$/.test(password),
  },
  {
    text: i18next.t("password-must-contain-at-least-one-number"),
    f: (password: string): boolean => /^.*(?=.*\d).*$/.test(password),
  },
  {
    text: i18next.t(
      "password-must-contain-at-least-one-special-case-character",
    ),
    f: (password: string): boolean =>
      /^.*((?=.*[!@#$£%^&*()\-_=+{};:,<.>]){1}).*$/.test(password),
  },
];
