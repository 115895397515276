import { ApolloError, useMutation } from "@apollo/client";
import { Alert, message, Typography } from "antd";
import { CheckOutlined, CloseOutlined, UserOutlined } from "@ant-design/icons";
import { Formik } from "formik";
import { Form, Input, SubmitButton, Switch } from "formik-antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { UPDATE_NAME, USER_QUERY } from "ui/Queries";
import { UpdateNameVars, User } from "ui/Types";
import * as Yup from "yup";

const { Text } = Typography;

type UserFormValues = {
  name: string | undefined;
  autoSubscribe: boolean | undefined;
};

type UserFormProps = {
  user: User | undefined;
};

const UserForm = (props: UserFormProps) => {
  const { t } = useTranslation();

  const schema = Yup.object({
    name: Yup.string().label(t("name")).required(t("please-provide-your-name")),
  });

  const initialValues: UserFormValues = {
    name: props.user?.name,
    autoSubscribe: props.user?.profile.autoSubscribe ?? false,
  };
  const [saveError, setSaveError] = useState<ApolloError | null>(null);

  const [updateName, { loading }] = useMutation<UpdateNameVars>(UPDATE_NAME, {
    onCompleted: () =>
      message.success(t("the-user-details-were-updated-successfully")),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={async (values, actions) => {
        setSaveError(null);
        try {
          await updateName({
            variables: {
              name: values.name,
              autoSubscribe: values.autoSubscribe,
            },
            refetchQueries: [{ query: USER_QUERY }],
          });
        } catch (error) {
          setSaveError(error as ApolloError);
        }
        actions.setSubmitting(false);
      }}
    >
      {(formik) => (
        <div>
          <Form>
            <Form.Item name="name">
              <Input
                name="name"
                placeholder={t("name")}
                prefix={<UserOutlined />}
              />
            </Form.Item>
            <Form.Item name="autoSubscribe">
              <Text strong>{t("auto-subscribe")}</Text>
              <br />
              <Switch
                name="autoSubscribe"
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
              />
            </Form.Item>
            <SubmitButton
              disabled={
                formik.isSubmitting ||
                (formik.values.name === props.user?.name &&
                  formik.values.autoSubscribe ===
                    (props.user?.profile.autoSubscribe ?? false)) ||
                !formik.isValid
              }
              loading={loading}
            >
              {t("save")}
            </SubmitButton>
          </Form>
          {saveError && (
            <Alert
              style={{ marginTop: 12 }}
              message={t("error")}
              description={
                saveError && saveError.graphQLErrors.length > 0
                  ? saveError.graphQLErrors[0].message
                  : t("there-has-been-an-error-saving-your-details")
              }
              type="error"
            />
          )}
        </div>
      )}
    </Formik>
  );
};

export default UserForm;
