import { Space } from "antd";
import { PASSWORD_RULES } from "ui/Constants";
import PasswordRule from "./PasswordRule";

type PasswordRuleListProps = {
  password: string | undefined;
  showWhenEmpty?: boolean;
};

const PasswordRuleList = (props: PasswordRuleListProps): JSX.Element => {
  return props.showWhenEmpty ||
    (props.password && props.password.length > 0) ? (
    <Space direction={"vertical"}>
      {PASSWORD_RULES().map((r, idx) => (
        <PasswordRule
          key={idx}
          text={r.text}
          pass={r.f(props.password ?? "")}
        />
      ))}
    </Space>
  ) : (
    <></>
  );
};

export default PasswordRuleList;
