import {
  Button,
  Card,
  Modal,
  Select,
  Space,
  Switch,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Notice, Tenant } from "ui/Types";
import { OperationVariables } from "@apollo/client";
import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  SendOutlined,
} from "@ant-design/icons";
import { ChromePicker } from "react-color";
import { CONSTANTS } from "@bcspi/common";

const { Text } = Typography;
const { Option } = Select;

type ViewProps = {
  notice: Notice;
  saveNotice: (variables: OperationVariables) => void;
  toggleNotice: (variables: OperationVariables) => void;
  onSendNotice: (notice: Notice, tenants: string[]) => void;
  onDeleteNotice: (notice: Notice) => void;
  tenants: Tenant[];
};

type RawValueType = string | number;
type LabelValueType = {
  key?: string | number;
  value?: RawValueType;
  label?: React.ReactNode;
  isCacheable?: boolean;
};
type DefaultValueType =
  | RawValueType
  | RawValueType[]
  | LabelValueType
  | LabelValueType[];
type CustomTagProps = {
  label: React.ReactNode;
  value: DefaultValueType;
  disabled: boolean;
  onClose: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  closable: boolean;
};

const View = ({
  notice,
  saveNotice,
  toggleNotice,
  onSendNotice,
  onDeleteNotice,
  tenants,
}: ViewProps) => {
  const { t } = useTranslation();

  const savedContentRef = useRef(notice.content);
  const newContentRef = useRef(notice.content);
  const [editingContent, setEditingContent] = useState(false);
  const [state, setState] = useState("");

  const tagRender = (p: CustomTagProps) => {
    const { label, closable, onClose } = p;

    return (
      <Tag color={CONSTANTS.COLOURS.TEAL} closable={closable} onClose={onClose}>
        <>{label}</>
      </Tag>
    );
  };

  return (
    <>
      <Card
        title={
          <Text style={{ maxWidth: "500px" }} ellipsis>
            {notice.content}
          </Text>
        }
        key={notice._id}
        style={{
          marginBottom: "10px",
          border: `1px solid ${CONSTANTS.COLOURS.BORDER}`,
        }}
        extra={
          <Space>
            <Tooltip title={t("send")}>
              <Button
                type="link"
                icon={<SendOutlined />}
                onClick={() =>
                  onSendNotice(
                    notice,
                    (notice.tenants ?? []).map(
                      (t: string) =>
                        tenants.find((x) => x._id === t)?.name ?? "",
                    ),
                  )
                }
              />
            </Tooltip>
            <Tooltip title={t("remove")}>
              <Button
                type="link"
                icon={<DeleteOutlined />}
                danger
                onClick={() => onDeleteNotice(notice)}
              />
            </Tooltip>
          </Space>
        }
        size="small"
      >
        <Space direction="vertical" style={{ width: "100%" }}>
          {/* <Space style={{ width: "100%" }}> */}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "8px",
              width: "100%",
            }}
          >
            <div>{`${t("content")}:`}</div>
            <div
              style={{ width: "100%" }}
              onBlur={() => setEditingContent(false)}
            >
              <Text
                strong
                style={{ width: "100%" }}
                editable={{
                  tooltip: t("click-to-edit"),
                  editing: editingContent,
                  autoSize: true,
                  maxLength: CONSTANTS.DEFAULTS.MAX_NOTICE_LENGTH,
                  onStart: () => setEditingContent(true),
                  onChange: (v) => {
                    newContentRef.current = v;
                  },
                  onCancel: () => {
                    newContentRef.current = savedContentRef.current;
                    setEditingContent(false);
                  },
                  onEnd: () => {
                    if (newContentRef.current !== savedContentRef.current)
                      saveNotice({
                        variables: {
                          ...notice,
                          content: newContentRef.current,
                        },
                        onCompleted: () => {
                          savedContentRef.current = newContentRef.current;
                        },
                      });
                    setEditingContent(false);
                  },
                }}
              >
                {notice.content}
              </Text>
            </div>
            {editingContent && (
              <div style={{ whiteSpace: "nowrap" }}>
                {t("press-enter-to-save")}
              </div>
            )}
          </div>
          {/* </Space> */}
          <Space size={14}>
            <Space>
              {`${t("active")}:`}
              <Switch
                checked={notice.active}
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                onChange={() => {
                  toggleNotice({
                    variables: {
                      _id: notice._id,
                    },
                  });
                }}
              />
            </Space>
            <Space>
              {`${t("colour")}:`}
              <div
                style={{
                  width: "20px",
                  height: "20px",
                  background:
                    notice.style?.background ?? CONSTANTS.COLOURS.DANGER_RED,
                  borderRadius: "5px",
                  border: "1px solid black",
                }}
                onClick={() => setState("background")}
              ></div>
            </Space>
            <Space>
              {`${t("text")}:`}
              <div
                style={{
                  width: "20px",
                  height: "20px",
                  background: notice.style?.color ?? "black",
                  borderRadius: "5px",
                  border: "1px solid black",
                }}
                onClick={() => setState("color")}
              ></div>
            </Space>
            <Space style={{ width: "100%" }}>
              {`${t("tenants")} (${
                !notice.tenants || notice.tenants.length == 0
                  ? t("all")
                  : notice.tenants.length
              }):`}
              <Select
                mode="multiple"
                allowClear
                style={{ width: "100%", minWidth: "200px" }}
                placeholder={t("select-tenants-placeholder")}
                defaultValue={notice.tenants ?? []}
                onChange={(values) =>
                  saveNotice({
                    variables: {
                      ...notice,
                      tenants: values,
                    },
                  })
                }
                tagRender={tagRender}
              >
                {tenants.map((t) => (
                  <Option key={t._id} value={t._id}>
                    {t.name}
                  </Option>
                ))}
              </Select>
            </Space>
          </Space>
        </Space>
      </Card>
      {["background", "borderColor", "color"].includes(state) && (
        <Modal
          open={["background", "borderColor", "color"].includes(state)}
          mask={false}
          onCancel={() => setState("")}
          footer={false}
          closable={false}
          centered
          bodyStyle={{ padding: "0px" }}
          width={225}
        >
          <ChromePicker
            color={
              state === "background"
                ? notice.style?.background ?? CONSTANTS.COLOURS.DANGER_RED
                : state === "borderColor"
                  ? notice.style?.borderColor ?? CONSTANTS.COLOURS.GOLDEN_POPPY
                  : notice.style?.color ?? "black"
            }
            disableAlpha
            onChangeComplete={(colour) =>
              saveNotice({
                variables: {
                  ...notice,
                  style: { ...notice.style, [state]: colour.hex },
                },
              })
            }
          />
        </Modal>
      )}
    </>
  );
};

export default View;
