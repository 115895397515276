import { CSSProperties, useContext } from "react";

import { Layout, Space } from "antd";
import PublicNavigation from "./PublicNavigation";
import AuthenticatedNavigation from "./AuthenticatedNavigation";
// import AlertInbox from "../AlertInbox";
// import AlertInboxConfiguration from "../AlertInbox/configuration";

import logo from "../../images/bc_logo.png";
import { SystemContext } from "ui/App";
import { useNavigate } from "react-router-dom";
import { CONSTANTS } from "@bcspi/common";

const { URL_ROOT } = CONSTANTS;
const { Header } = Layout;

const NavigationHeader: React.FC = () => {
  const systemContext = useContext(SystemContext);
  const navigate = useNavigate();

  const { user } = systemContext;

  // const [inboxVisible, setInboxVisible] = useState(false);

  return (
    <>
      <Header
        style={{
          paddingLeft: 16,
          paddingRight: 16,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: CONSTANTS.COLOURS.BCLOGO_BG,
        }}
      >
        <img
          alt="logo"
          style={styles.logo as CSSProperties}
          src={logo}
          onClick={() => navigate(URL_ROOT)}
        />
        <Space
          style={{ color: "white", marginLeft: "24px", whiteSpace: "nowrap" }}
        >
          {systemContext.user?.tenant.name}
          {user && user._id && (
            <AuthenticatedNavigation onShowInbox={() => false} />
          )}
          {(!user || !user._id) && <PublicNavigation />}
        </Space>
      </Header>
      {/* <Drawer
        title={
          <span>
            <BellOutlined /> Notifications
          </span>
        }
        width={640}
        placement="right"
        closable={true}
        onClose={() => setInboxVisible(false)}
        visible={inboxVisible}
        destroyOnClose={true}
      >
        <Tabs defaultActiveKey="notifications">
          <TabPane
            tab={
              <span>
                <InboxOutlined style={{ fontSize: 16 }} />
                Active Alerts
              </span>
            }
            key="notifications"
          >
            <AlertInbox onClose={() => setInboxVisible(false)} />
          </TabPane>
          <TabPane
            tab={
              <span>
                <SettingOutlined style={{ fontSize: 16 }} />
                Configuration
              </span>
            }
            key="configuration"
          >
            <AlertInboxConfiguration />
          </TabPane>
        </Tabs>
      </Drawer> */}
    </>
  );
};

const styles = {
  logo: {
    width: 120,
    height: 31,
    backgroundColor: "#1f1a1b",
    background: "#1f1a1b",
    margin: "16px 24px 16px 0",
    float: "left",
  },
};

export default NavigationHeader;
