import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  LogoutOutlined,
  UserOutlined,
  DownOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";

import { Dropdown, Button, Modal, Descriptions, Space, MenuProps } from "antd";

import { SystemContext } from "ui/App";
import { useNavigate, useLocation } from "react-router-dom";
import { CONSTANTS } from "@bcspi/common";
import { formatDate } from "../Utils";
import dayjs from "dayjs";

const { URL_ROOT } = CONSTANTS;

type ViewProps = {
  onShowInbox: () => void;
};

const AuthenticatedNavigation: React.FC<ViewProps> = () => {
  const { t } = useTranslation();

  const { user, logout, systemInfo } = useContext(SystemContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [showAbout, setShowAbout] = useState(false);
  let selectedKeys: string[] = [];
  if (location.pathname === URL_ROOT) {
    selectedKeys = [URL_ROOT];
  }

  const menuItems: MenuProps["items"] = [
    {
      key: "profile",
      onClick: () => navigate(`${URL_ROOT}/profile`),
      label: (
        <Space>
          <UserOutlined />
          <span>{t("profile")}</span>
        </Space>
      ),
    },
    {
      key: "about",
      onClick: () => setShowAbout(true),
      label: (
        <Space>
          <InfoCircleOutlined />
          <span>{t("about")}</span>
        </Space>
      ),
    },
    {
      key: "logout",
      onClick: () => logout && logout(),
      label: (
        <Space>
          <LogoutOutlined />
          <span>{t("logout")}</span>
        </Space>
      ),
    },
  ];

  return (
    <div
      style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
    >
      <Dropdown
        menu={{
          defaultSelectedKeys: [URL_ROOT],
          selectedKeys: selectedKeys,
          items: menuItems,
        }}
        trigger={["click"]}
      >
        <Button>
          {user?.name} <DownOutlined />
        </Button>
      </Dropdown>
      <Modal
        open={showAbout}
        title={t("about")}
        footer={null}
        onCancel={() => setShowAbout(false)}
        width={600}
      >
        <Descriptions title={t("app-title")} bordered column={1}>
          <Descriptions.Item label={t("version")}>
            {systemInfo?.version}
          </Descriptions.Item>
          <Descriptions.Item label={t("installation-date")}>
            {systemInfo ? formatDate(dayjs(systemInfo?.born)) : ""}
          </Descriptions.Item>
          <Descriptions.Item label={t("language")}>
            {
              CONSTANTS.I18N.find(
                (l: { code: string; name: string }) =>
                  l.code === (user?.profile.locale ?? "en-us"),
              ).name
            }
          </Descriptions.Item>
        </Descriptions>
      </Modal>
    </div>
  );
};

export default AuthenticatedNavigation;
