import { useContext, useRef, useState } from "react";
import { Form, Input, SubmitButton } from "formik-antd";
import { Formik } from "formik";
import { Button, Alert, Divider, Modal, Typography } from "antd";
import {
  UPDATE_INSTANCE,
  ALL_INSTANCES,
  USER_QUERY,
  ALL_USERS,
  INSTANCE_NAME_EXISTS,
} from "../../Queries";
import { ApolloError, useMutation, useLazyQuery } from "@apollo/client";
import * as Yup from "yup";
import { DashboardOutlined } from "@ant-design/icons";
import { SystemContext } from "ui/App";
import { useTranslation } from "react-i18next";

const { success, error } = Modal;
const { Text } = Typography;

type ViewProps = {
  onCancel: () => void;
  onComplete: () => void;
};

const View = ({ onComplete, onCancel }: ViewProps) => {
  const systemContext = useContext(SystemContext);

  const { t } = useTranslation();

  const [createInstance, { loading }] = useMutation(UPDATE_INSTANCE, {
    refetchQueries: [
      { query: ALL_INSTANCES },
      { query: ALL_USERS },
      { query: USER_QUERY },
    ],
  });

  const [checkName, { data }] = useLazyQuery(INSTANCE_NAME_EXISTS, {
    fetchPolicy: "network-only",
  });

  const Schema = Yup.object({
    name: Yup.string()
      .label(t("name"))
      .required(t("name-is-required"))
      .min(3)
      .max(62 - (systemContext.user?.tenant.name.length ?? 3))
      .test("name", t("this-name-has-already-been-used"), () => {
        // debugger;
        return !data?.instanceNameExists ?? true;
      }),
    licenseAllocation: Yup.number()
      .label(t("license-allocation"))
      .required(t("license-allocation-is-required"))
      .min(0)
      .max(
        (systemContext.user?.profile.licenseAllocation ?? 0) -
          (systemContext.user?.profile.resourceUsage.licensesUsed ?? 0),
      ),
    sessionMaxAgeMin: Yup.number()
      .label(t("session-logout"))
      .required(t("session-logout-is-required"))
      .min(1)
      .max(1440, t("session-logout-must-be-not-be-more-than-24-hours")),
  });

  const initialValues = useRef({
    name: "",
    description: "",
    licenseAllocation:
      (systemContext.user?.profile.licenseAllocation ?? 0) -
      (systemContext.user?.profile.resourceUsage.licensesUsed ?? 0),
    sessionMaxAgeMin: 30,
  });

  const [errors, setErrors] = useState<String[]>([]);

  return (
    <Formik
      validateOnMount
      initialValues={initialValues.current}
      validationSchema={Schema}
      onSubmit={async (data) => {
        setErrors([]);
        try {
          const res = await createInstance({
            variables: { ...data },
          });
          if (res.data.saveServiceInstance)
            success({
              title: t("success"),
              content: <Text>{t("the-instance-request-was-successful")}</Text>,
              onOk: async () => {
                onComplete();
              },
            });
          else
            error({
              title: t("error"),
              content: (
                <Text>
                  {t(
                    "an-error-occurred-requesting-the-instance-please-contact-your-administrator",
                  )}
                </Text>
              ),
              onOk: async () => {
                onComplete();
              },
            });
          // onComplete();
        } catch (error) {
          if (error && (error as ApolloError).graphQLErrors) {
            setErrors(
              (error as ApolloError).graphQLErrors.map(
                ({ message: msg }) => msg,
              ),
            );
          }
        }
      }}
    >
      {(formik) => (
        <Form>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Form.Item name="name">
              <Input
                autoFocus
                name="name"
                prefix={
                  <DashboardOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                }
                placeholder={t("instance-name-placeholder")}
                onChange={(e) =>
                  checkName({
                    variables: { name: e.target.value },
                  })
                }
              />
            </Form.Item>
            <Form.Item name="description">
              <Input
                name="description"
                placeholder={t("description-placeholder")}
              />
            </Form.Item>
            <Form.Item name="licenseAllocation">
              <Input
                style={{}}
                type={"number"}
                min={0}
                name="licenseAllocation"
                prefix={`${t("license-allocation")} (max ${
                  (systemContext.user?.profile.licenseAllocation ?? 0) -
                  (systemContext.user?.profile.resourceUsage.licensesUsed ?? 0)
                }): `}
                placeholder={t("license-allocation-placeholder")}
              />
            </Form.Item>
            <Form.Item name="sessionMaxAgeMin">
              <Input
                style={{}}
                type={"number"}
                min={1}
                max={1440}
                name="sessionMaxAgeMin"
                prefix={`${t("user-idle-session-logout-minutes-prompt")} `}
                placeholder={t("session-logout-placeholder")}
              />
            </Form.Item>
            {errors.length > 0 && (
              <div style={{ marginTop: 12 }}>
                {errors.map((message, i) => (
                  <Alert key={i} message={message} type="error" showIcon />
                ))}
              </div>
            )}
            <Divider />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Button style={{}} onClick={() => onCancel()}>
                {t("cancel")}
              </Button>
              <SubmitButton
                loading={loading}
                style={{}}
                disabled={formik.isSubmitting || !formik.isValid}
              >
                {t("create-instance")}
              </SubmitButton>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default View;
