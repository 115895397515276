module.exports = {
  URL_ROOT: "/bcspi",
  NEW_ACCOUNT_REQUEST_EMAIL: "bc.saas@theblackchair.com",
  DEFAULTS: {
    DEFAULT_PAGE_SIZE: 30,
    DEFAULT_APP_LINKS: [
      {
        appName: "Administration",
        link: "/bcadmin",
        statusLink: "/bcadmin/health",
      },
      {
        appName: "Audit and Automation",
        link: "/launchpad",
      },
      {
        appName: "Advanced Search",
        link: "/search",
        statusLink: "/search/health",
      },
      {
        appName: "Business User Portal",
        link: "/bup",
        statusLink: "/bup/health",
      },
      {
        appName: "Enterprise Translation Platform",
        link: "/etp",
        statusLink: "/etp/health",
      },
      {
        appName: "Reporting",
        link: "/reportinglaunchpad",
        service: "reporting",
      },
    ],
    MAX_NOTICE_LENGTH: 320,
    NAMESPACE_DOMAIN: "theblackchair.net",
    SMTP_RETRY_WAIT_MS: 10000,
  },
  // USER_TYPE: {
  // 	SUPER_USER: "super_user",
  // 	SERVICE_ADMIN: "service_admin",
  // 	SERVICE_OPERATOR: "service_operator",
  // },
  SYSTEM_TYPES: {
    SPI: "spi",
  },
  DEFAULT_TENANT: "Default",
  // ALL_USERS_GROUP: "All Users",
  COLOURS: {
    PRIMARY: ["#00B3BD", "#004862"],
    LINK: ["#04EAF6", "#0052BD"],
    TEXT: ["rgba(255, 255, 255, 0.85)", "rgba(0, 0, 0, 0.88)"], // for those times when dark does not take - tabs DO push down the text colour for light/dark, some other containers do not
    GRID_BORDER: ["#424242", "#D9D9D9"], //already exists elsewhere
    BCLOGO_BG: "#1F1A1B",
    ANTD_COLORBGCONTAINER: ["#323232", "#FFFFFF"],

    WHITE: "#FFFFFF",
    DARK_BLUE: "#004862",
    TEAL: "#007980", //#00B3BD
    ORANGE: "#E39016",
    GOLDEN_POPPY: "#FFC300",
    YELLOW: "#FFDD00",
    PLUM: "#A75182",
    MARSHLAND: "#1C1D1B",
    GREEN: "#009371",
    DARK_GREEN: "#26625A",
    RED: "#CE181E",
    MARSHLAND_FADE: "rgba(28, 29, 27, 0.85)",
    BG_OFF_WHITE: "#F7F7F7",
    DARK_TEAL: "#00181A",
    LIGHT_TEAL: "#00C2CC",
    DANGER_RED: "#D0342C",
    BG_GREEN: "#99FF99",
    BG_RED: "#FF9999",
    BG_AMBER: "#FFDB99",
    BORDER: "#D9D9D9",
  },
  I18N: [
    { code: "en-us", name: "English" },
    { code: "fr-fr", name: "French" },
  ],
  BUILD_STATE: {
    QUEUED: "queued",
    BUILDING: "building",
    COMPLETED: "completed",
    DELETING: "deleting",
    DELETED: "deleted",
  },
  JENKINS_BUILD_RESULT: {
    ABORTED: "ABORTED",
    FAILURE: "FAILURE",
    SUCCESS: "SUCCESS",
    UNKNOWN: "UNKNOWN",
  },
  PLUGIN_COLOURS: {
    AmazonConnect: "#FF9900",
    ASMGR: "#DA291C",
    AvayaCM: "#DA291C",
    CME: "#FF4F1F",
    CMEOutbound: "#FF4F1F",
    CUCM: "#00A4DE",
    Five9: "#003B5D",
    GAX: "#FF4F1F",
    GenesysPureCloudPlugin: "#804000",
    NiceInContact: "#00A0DA",
    ORCSBC: "#F80000",
    SpotlightConfigFiles: "rgba(28, 29, 27, 0.85)",
  },
  PLUGIN_TEXT: {
    AmazonConnect: "Amazon Connect",
    ASMGR: "Avaya System Mgr",
    AvayaCM: "Avaya CM",
    CME: "Genesys Engage",
    CMEOutbound: "Genesys Engage Outbound",
    CUCM: "Cisco UCM",
    Five9: "Five 9",
    GAX: "Genesys GAX",
    GenesysPureCloudPlugin: "Genesys Cloud",
    NiceInContact: "NICE CXone",
    ORCSBC: "Oracle SBC",
    SpotlightConfigFiles: "Config Files",
  },
  NOTICE_TYPE: {
    INFO: "info",
  },
  DO_NOT_LOG: [
    "getServiceInstances",
    "me",
    "getDisplayNotice",
    "getDisplayNotices",
    "allTenants",
  ],
};
