import { theme, type ThemeConfig } from "antd";
import { getColour } from "./getColour";

export const getThemeConfig = (isDarkMode: boolean): ThemeConfig => {
  return {
    algorithm: isDarkMode ? theme.darkAlgorithm : theme.defaultAlgorithm,
    // algorithm: theme.compactAlgorithm,
    token: {
      colorPrimary: getColour({ isDarkMode }),
      colorLink: getColour({ colour: "LINK", isDarkMode }),
      colorInfo: getColour({ isDarkMode }),
      fontFamily: 'Tahoma, "Trebuchet MS", sans-serif;',
    },
  };
};
