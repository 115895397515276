import { useContext, useRef, useState } from "react";
import { Form, Input, SubmitButton, Select } from "formik-antd";
import { Formik } from "formik";
import { MailOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Alert, Divider, Typography } from "antd";
import {
  CREATE_USER,
  ALL_USERS,
  USER_QUERY,
  SYSTEM_QUERY,
  ALL_TENANTS,
  USER_EXISTS,
} from "../../Queries";
import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { SECURITY, CONSTANTS } from "@bcspi/common";
import * as Yup from "yup";
import { Tenant } from "ui/Types";
import { SystemContext } from "ui/App";
import { useTranslation } from "react-i18next";
// import i18next from "i18next";

const { Text } = Typography;

type ViewProps = {
  isInitial?: boolean;
  onCancel: () => void;
  onComplete: () => void;
};

const View = ({ onComplete, onCancel, isInitial = false }: ViewProps) => {
  const { t } = useTranslation();

  const systemContext = useContext(SystemContext);

  const [username, setUsername] = useState("");

  const [selectedTenant, setSelectedTenant] = useState<Tenant | undefined>(
    systemContext.user?.tenant,
  );

  const { data: dataUserExists } = useQuery(USER_EXISTS, {
    variables: { username },
  });

  const Schema = Yup.object({
    name: Yup.string().label(t("name")).required(t("name-is-required")).min(1),
    // password: Yup.string()
    //   .required("Please enter a password")
    //   // .min(8, i18next.t("minimum-length-is-x-characters"))
    //   .matches(
    //     /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
    //     i18next.t("password-must-contain")
    //   ),
    username: Yup.string()
      .label(t("email-address"))
      .email(t("please-enter-a-valid-email-address"))
      .min(5)
      .required(t("please-enter-a-valid-email-address"))
      .test(
        "name",
        t("this-email-address-has-already-been-used"),
        () => !dataUserExists?.userExists ?? true,
      ),
    accountType: Yup.string().required(t("select-an-account-type")).min(1),
    licenseAllocation: Yup.number()
      .label(t("license-allocation"))
      .required(t("license-allocation-is-required"))
      .min(0)
      .max(
        (selectedTenant?.licenseAllocation ?? 0) -
          (selectedTenant?.licensesAllocated ?? 0),
      ),
    instanceAllocation: Yup.number()
      .label(t("instance-allocation"))
      .required(t("instance-allocation-is-required"))
      .min(0)
      .max(
        (selectedTenant?.instanceAllocation ?? 0) -
          (selectedTenant?.instancesAllocated ?? 0),
      ),
    ...(!isInitial &&
      systemContext.user?.roles.includes(SECURITY.USER_ROLES.SUPER_USER) && {
        tenant: Yup.string().required(t("tenant-is-required")).min(1),
      }),
  });

  const [createUser] = useMutation(CREATE_USER, {
    refetchQueries: [
      { query: USER_QUERY },
      { query: ALL_USERS },
      { query: SYSTEM_QUERY },
    ],
    onCompleted: () => {
      onComplete();
    },
    onError: (error) => {
      if (error && (error as ApolloError).graphQLErrors) {
        setErrors(
          (error as ApolloError).graphQLErrors.map(({ message: msg }) => msg),
        );
      }
    },
  });
  const { data, loading: loadingTenants } = useQuery(ALL_TENANTS, {
    skip: !systemContext.user?.roles.includes(SECURITY.USER_ROLES.SUPER_USER),
  });

  const initialValues = useRef({
    accountType: SECURITY.ACCOUNT_TYPES.USER,
    roles: systemContext.user?.roles.includes(SECURITY.USER_ROLES.SUPER_USER)
      ? SECURITY.USER_ROLES.SPI_ADMIN
      : SECURITY.USER_ROLES.SPI_USER,
    licenseAllocation: 0,
    instanceAllocation: 0,
    tenant: isInitial
      ? CONSTANTS.DEFAULT_TENANT
      : systemContext.user?.roles.includes(SECURITY.USER_ROLES.SUPER_USER)
        ? ""
        : systemContext.user?.tenant._id,
  });

  const [errors, setErrors] = useState<String[]>([]);

  let tenantsList: JSX.Element[] = [];
  if (!loadingTenants && data && data.allTenants)
    tenantsList = data.allTenants
      .filter((tenant: Tenant) => tenant.name !== CONSTANTS.DEFAULT_TENANT)
      .map((tenant: Tenant) => (
        <Select.Option key={tenant._id} value={tenant._id}>
          {`${t("tenant-prompt")} ${tenant.name}`}
        </Select.Option>
      ));

  return (
    <Formik
      validateOnMount
      initialValues={initialValues.current}
      validationSchema={Schema}
      onSubmit={async (d, actions) => {
        setErrors([]);
        await createUser({ variables: { ...d } });
        actions.setSubmitting(false);
      }}
    >
      {(formik) => (
        <Form>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Form.Item name="name">
              <Input
                autoFocus
                name="name"
                prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                placeholder={t("full-name-placeholder")}
              />
            </Form.Item>
            <Form.Item name="username">
              <Input
                style={{}}
                name="username"
                prefix={<MailOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                placeholder={t("email-address-placeholder")}
                onChange={(e) => setUsername(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              name="tenant"
              hidden={
                isInitial ||
                !systemContext.user?.roles.includes(
                  SECURITY.USER_ROLES.SUPER_USER,
                )
              }
            >
              <Select
                name="tenant"
                placeholder={t("tenant-placeholder")}
                onChange={(e) =>
                  setSelectedTenant(
                    data.allTenants.find((tnt: Tenant) => tnt._id === e),
                  )
                }
              >
                {tenantsList}
              </Select>
            </Form.Item>
            <Form.Item name="accountType" hidden>
              {/* not currently using this but may do if we allow customers to create system api accounts */}
              <Select name="accountType" disabled={isInitial}>
                <Select.Option
                  key={SECURITY.ACCOUNT_TYPES.USER}
                  value={SECURITY.ACCOUNT_TYPES.USER}
                >
                  {t("account-type-prompt")} {SECURITY.ACCOUNT_TYPES.USER}
                </Select.Option>
                <Select.Option
                  key={SECURITY.ACCOUNT_TYPES.SYSTEM}
                  value={SECURITY.ACCOUNT_TYPES.SYSTEM}
                >
                  {t("account-type-prompt")} {SECURITY.ACCOUNT_TYPES.SYSTEM}
                </Select.Option>
              </Select>
            </Form.Item>
            <Form.Item name="roles" hidden={isInitial}>
              <Select name="roles">
                <Select.Option
                  key={SECURITY.USER_ROLES.SPI_ADMIN}
                  value={SECURITY.USER_ROLES.SPI_ADMIN}
                >
                  {`${t("role-prompt")} ${
                    SECURITY.ROLE_TITLES[SECURITY.USER_ROLES.SPI_ADMIN]
                  }`}
                </Select.Option>
                {!systemContext.user?.roles.includes(
                  SECURITY.USER_ROLES.SUPER_USER,
                ) ? (
                  <Select.Option
                    key={SECURITY.USER_ROLES.SPI_USER}
                    value={SECURITY.USER_ROLES.SPI_USER}
                  >
                    {`${t("role-prompt")} ${
                      SECURITY.ROLE_TITLES[SECURITY.USER_ROLES.SPI_USER]
                    }`}
                  </Select.Option>
                ) : null}
              </Select>
            </Form.Item>
            <Form.Item name="licenseAllocation" hidden={isInitial}>
              <Input
                style={{}}
                type={"number"}
                min={0}
                max={
                  (selectedTenant?.licenseAllocation ?? 0) -
                  (!systemContext.user?.roles.includes(
                    SECURITY.USER_ROLES.SUPER_USER,
                  )
                    ? systemContext.user?.profile.resourceUsage
                        .tenantLicensesAllocated ?? 0
                    : selectedTenant?.licensesAllocated ?? 0)
                }
                name="licenseAllocation"
                prefix={`${t("license-allocation")} (max ${
                  (selectedTenant?.licenseAllocation ?? 0) -
                  (!systemContext.user?.roles.includes(
                    SECURITY.USER_ROLES.SUPER_USER,
                  )
                    ? systemContext.user?.profile.resourceUsage
                        .tenantLicensesAllocated ?? 0
                    : selectedTenant?.licensesAllocated ?? 0)
                }): `}
                placeholder={t("license-allocation-placeholder")}
              />
            </Form.Item>
            <Form.Item name="instanceAllocation" hidden={isInitial}>
              <Input
                style={{}}
                type={"number"}
                min={0}
                max={
                  (selectedTenant?.instanceAllocation ?? 0) -
                  (!systemContext.user?.roles.includes(
                    SECURITY.USER_ROLES.SUPER_USER,
                  )
                    ? systemContext.user?.profile.resourceUsage
                        .tenantInstancesAllocated ?? 0
                    : selectedTenant?.instancesAllocated ?? 0)
                }
                name="instanceAllocation"
                prefix={`${t("instance-allocation")} (max ${
                  (selectedTenant?.instanceAllocation ?? 0) -
                  (!systemContext.user?.roles.includes(
                    SECURITY.USER_ROLES.SUPER_USER,
                  )
                    ? systemContext.user?.profile.resourceUsage
                        .tenantInstancesAllocated ?? 0
                    : selectedTenant?.instancesAllocated ?? 0)
                }): `}
                placeholder={t("instance-allocation-placeholder")}
              />
            </Form.Item>
            {/* <Form.Item name="password">
              <Input
                style={{}}
                name="password"
                prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                type="password"
                placeholder="Password"
              />
            </Form.Item> */}
            {errors.length > 0 && (
              <div style={{ marginTop: 12 }}>
                {errors.map((message, i) => (
                  <Alert key={i} message={message} type="error" showIcon />
                ))}
              </div>
            )}
            <Divider style={{ marginTop: "8px", marginBottom: "8px" }} />
            <div style={{ marginBottom: "8px" }}>
              {isInitial ? (
                <Text>
                  {t(
                    "you-will-receive-an-email-containing-your-new-password-message",
                  )}
                </Text>
              ) : (
                <Text>
                  {t(
                    "the-user-will-receive-an-email-containing-their-new-password-message",
                  )}
                </Text>
              )}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Button
                style={{ visibility: isInitial ? "hidden" : "visible" }}
                onClick={() => onCancel()}
              >
                {t("cancel")}
              </Button>
              <SubmitButton
                loading={formik.isSubmitting}
                style={{}}
                disabled={formik.isSubmitting || !formik.isValid}
              >
                {t("create-user")}
              </SubmitButton>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default View;
