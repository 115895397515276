import Linkify from "react-linkify";
import { Notice } from "ui/Types";
// import { TextLoop } from "react-text-loop-next";
import { Carousel } from "antd";

type NoticeBannerProps = {
  notices: Notice[];
  duration: number;
};

const NoticeBanner = ({
  notices,
  duration,
}: NoticeBannerProps): JSX.Element => {
  return (
    <div
      style={{
        display: "block",
        background: "black",
      }}
    >
      <Carousel autoplay autoplaySpeed={duration} dots={false} fade>
        {notices.map((notice, idx) => (
          <div key={idx}>
            <h3
              style={{
                marginTop: 0,
                marginBottom: 0,
                textAlign: "center",
                ...notice.style,
              }}
            >
              <Linkify
                componentDecorator={(decoratedHref, decoratedText, key) => (
                  <a
                    target="blank"
                    href={decoratedHref}
                    key={key}
                    style={{
                      textDecoration: "underline",
                      color: notice.style?.color ?? "black",
                    }}
                  >
                    {decoratedText}
                  </a>
                )}
              >
                {notice.content}
              </Linkify>
            </h3>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default NoticeBanner;
