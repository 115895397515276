const { USER_ROLES, SYSTEM_ROLES, ROLE_TITLES } = require('./roles');
const ACCOUNT_TYPES = {
    USER: "User",
    SYSTEM: "System"
};

const ACCOUNT_STATUS = {
    ACTIVE: "Active",
    DISABLED: "Disabled"
};

function hasRole(userRoles, role) {
    return userRoles.indexOf(role) > -1;
}

function isAdministrator(userRoles) {
    return hasRole(userRoles, USER_ROLES.SUPER_USER) || hasRole(userRoles, USER_ROLES.SPI_ADMIN);
}

function getUserRoles() {
    return Object.keys(USER_ROLES).map(key => {
        const role = USER_ROLES[key];
        return {
            title: ROLE_TITLES[role],
            role
        };
    });
}

function getSystemRoles() {
    return Object.keys(SYSTEM_ROLES).map(key => {
        const role = SYSTEM_ROLES[key];
        return {
            title: ROLE_TITLES[role],
            role
        };
    });
}
module.exports = {
    USER_ROLES,
    SYSTEM_ROLES,
    ACCOUNT_TYPES,
    ACCOUNT_STATUS,
    ROLE_TITLES,
    hasRole,
    isAdministrator,
    getUserRoles,
    getSystemRoles

};