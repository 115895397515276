import { useRef, useState } from "react";
import { Form, Input, SubmitButton } from "formik-antd";
import { Formik } from "formik";
import { GroupOutlined } from "@ant-design/icons";
import { Button, Alert, Divider } from "antd";
import {
  SAVE_TENANT,
  ALL_TENANTS,
  TENANT_EXISTS,
  ALL_USERS,
  USER_QUERY,
} from "../../Queries";
import { ApolloError, useMutation, useQuery } from "@apollo/client";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";

type ViewProps = {
  onCancel: () => void;
  onComplete: () => void;
};

const View = ({ onComplete, onCancel }: ViewProps) => {
  const { t } = useTranslation();

  const [createTenant, { loading }] = useMutation(SAVE_TENANT, {
    refetchQueries: [
      { query: ALL_TENANTS },
      { query: ALL_USERS },
      { query: USER_QUERY },
    ],
  });

  const [name, setName] = useState("");

  const { data } = useQuery(TENANT_EXISTS, {
    variables: { name },
  });

  const Schema = Yup.object({
    name: Yup.string()
      .label(t("name"))
      .required(t("name-is-required"))
      .min(3)
      .max(59)
      .test(
        "name",
        t("this-name-has-already-been-used"),
        () => !data?.tenantExists ?? true,
      ),
    licenseAllocation: Yup.number()
      .label(t("license-allocation"))
      .required(t("license-allocation-is-required"))
      .min(0),
    instanceAllocation: Yup.number()
      .label(t("instance-allocation"))
      .required(t("instance-allocation-is-required"))
      .min(0),
  });

  const initialValues = useRef({
    name: "",
    licenseAllocation: 0,
    instanceAllocation: 0,
  });

  const [errors, setErrors] = useState<String[]>([]);

  return (
    <Formik
      validateOnMount
      initialValues={initialValues.current}
      validationSchema={Schema}
      onSubmit={async (d) => {
        setErrors([]);
        try {
          await createTenant({
            variables: { ...d },
          });
          onComplete();
        } catch (error) {
          if (error && (error as ApolloError).graphQLErrors) {
            setErrors(
              (error as ApolloError).graphQLErrors.map(
                ({ message: msg }) => msg,
              ),
            );
          }
        }
      }}
    >
      {(formik) => (
        <Form>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Form.Item name="name">
              <Input
                autoFocus
                name="name"
                prefix={<GroupOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                placeholder={t("tenant-name-placeholder")}
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Item>
            <Form.Item name="licenseAllocation">
              <Input
                style={{}}
                type={"number"}
                min={0}
                name="licenseAllocation"
                prefix={`${t("license-allocation-prompt")} `}
                placeholder={t("license-allocation-placeholder")}
              />
            </Form.Item>
            <Form.Item name="instanceAllocation">
              <Input
                style={{}}
                type={"number"}
                min={0}
                name="instanceAllocation"
                prefix={`${t("instance-allocation-prompt")} `}
                placeholder={t("instance-allocation-placeholder")}
              />
            </Form.Item>
            {errors.length > 0 && (
              <div style={{ marginTop: 12 }}>
                {errors.map((message, i) => (
                  <Alert key={i} message={message} type="error" showIcon />
                ))}
              </div>
            )}
            <Divider />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Button style={{}} onClick={() => onCancel()}>
                {t("cancel")}
              </Button>
              <SubmitButton
                loading={loading}
                style={{}}
                disabled={formik.isSubmitting || !formik.isValid}
              >
                {t("create-tenant")}
              </SubmitButton>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default View;
