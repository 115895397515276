import dayjs from "dayjs";
// import en_GB from "antd/lib/locale/en_GB";
import { Locale } from "antd/es/locale";
import en_US from "antd/es/locale/en_US";
import fr_FR from "antd/es/locale/fr_FR";
// import de_DE from "antd/lib/locale/de_DE";
// import es_ES from "antd/lib/locale/es_ES";
// import it_IT from "antd/lib/locale/it_IT";
// import ro_RO from "antd/lib/locale/ro_RO";
// import bg_BG from "antd/lib/locale/bg_BG";
import moment from "moment";

import "dayjs/locale/fr";

export const getLocale = (localeCode: string): Locale => {
  let stringCode;
  let locale;
  switch (localeCode.toLowerCase().replace(/[-_ ]/g, "")) {
    case "frfr":
      stringCode = "fr";
      locale = fr_FR;
      break;
    default:
      stringCode = "en";
      locale = en_US;
      break;
  }
  window.localStorage.setItem("i18nextLng", stringCode);
  dayjs.locale(stringCode);
  moment.locale(stringCode);
  return locale;
};
