import { Modal } from "antd";
import CreateInstanceForm from "./CreateInstanceForm";
import { useTranslation } from "react-i18next";

type ViewProps = {
  visible: boolean;
  onCancel: () => void;
  onComplete: () => void;
};

const View = ({ visible, onCancel, onComplete }: ViewProps) => {
  const { t } = useTranslation();
  return (
    <Modal
      title={t("new-instance")}
      open={visible}
      destroyOnClose={true}
      onCancel={() => onCancel()}
      footer={null}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
          justifyContent: "center",
        }}
      >
        <CreateInstanceForm
          // refetchOnComplete={true}
          onComplete={() => onComplete()}
          onCancel={() => onCancel()}
        />
      </div>
    </Modal>
  );
};

export default View;
