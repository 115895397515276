import "classlist-polyfill";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import ReactDOM from "react-dom/client";
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  from,
  ApolloLink,
} from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
import { onError } from "@apollo/client/link/error";
import { CONSTANTS } from "@bcspi/common";
import App from "./ui/App";
import "es6-promise/auto";
import "./i18n";
import { sessionExpired } from "ui/components/Utils";
// import i18n from "./i18n";
// import { I18nextProvider } from "react-i18next";

import "isomorphic-fetch";

const uploadLink = createUploadLink({
  uri: `${CONSTANTS.URL_ROOT}/api/graphql`,
  headers: {
    "keep-alive": "true",
  },
});

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    // graphQLErrors.forEach(({ message, locations, path }) =>
    //   console.log(
    //     `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
    //   )
    // );
    if (graphQLErrors.some((e) => e.message === "Not Authorised!")) {
      sessionExpired();
    }
  }
  // if (networkError) console.log(`[Network error]: ${networkError}`);
});

export const client = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      ServiceInstance: {
        fields: {
          deviceInfo: {
            merge(_existing, incoming: any[]) {
              return incoming;
            },
          },
        },
      },
    },
  }),
  link: from([errorLink, uploadLink as unknown as ApolloLink]),
});

const ApolloApp = () => (
  <ApolloProvider client={client}>
    {/* <I18nextProvider i18n={i18n}> */}
    <App />
    {/* </I18nextProvider> */}
  </ApolloProvider>
);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(<ApolloApp />);
