import { CSSProperties, useState } from "react";
import { ApolloError, useMutation, useQuery } from "@apollo/client";
import {
  ALL_TENANTS,
  ALL_USERS,
  DELETE_TENANT,
  USER_QUERY,
} from "../../Queries";
import { DeleteOutlined, MoreOutlined } from "@ant-design/icons";
import {
  Card,
  List,
  Button,
  Modal,
  message,
  Popover,
  Typography,
  Tag,
  Space,
} from "antd";
import { Tenant } from "ui/Types";
import { CONSTANTS } from "@bcspi/common";
import { useTranslation } from "react-i18next";

const { Text } = Typography;
const confirm = Modal.confirm;

type TenantRowProps = {
  item: Tenant;
  selectedTenant: Tenant | undefined;
  onSelect: (item?: Tenant) => void;
  nameFilter?: string;
};

const TenantRow = ({
  item,
  selectedTenant,
  onSelect,
  nameFilter,
}: TenantRowProps) => {
  const { t } = useTranslation();

  const [deleteTenant] = useMutation(DELETE_TENANT, {
    refetchQueries: [
      {
        query: ALL_TENANTS,
        variables: {
          nameFilter,
        },
      },
      { query: ALL_USERS },
      { query: USER_QUERY },
    ],
  });
  const [popoverOpen, setPopoverOpen] = useState(false);

  let cellBackgroundColour = "#ffffff";
  const isSelected =
    selectedTenant && selectedTenant._id === item._id ? true : false;
  if (isSelected) {
    cellBackgroundColour = "rgba(186, 231, 255, 0.5)";
  }
  return (
    <div key={item._id} style={{ padding: 2, width: "100%" }}>
      <Card
        size="small"
        hoverable
        style={{ backgroundColor: cellBackgroundColour }}
        onClick={() => {
          onSelect(item);
        }}
        title={
          <Space>
            <Text strong>{item.name}</Text>
            {item.name === CONSTANTS.DEFAULT_TENANT && (
              <Tag style={{ marginLeft: 8 }} color="green">
                {t("system")}
              </Tag>
            )}
          </Space>
        }
        extra={
          item.name !== CONSTANTS.DEFAULT_TENANT && (
            <Popover
              content={
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{}}>
                    <Button
                      type="link"
                      style={{ color: "#fd7273" }}
                      onClick={(e) => {
                        e.preventDefault();

                        //Check they want do do that
                        confirm({
                          title: t("delete"),
                          content: t(
                            "are-you-sure-you-want-to-delete-this-tenant-questions",
                          ),
                          async onOk() {
                            setPopoverOpen(false);
                            try {
                              await deleteTenant({
                                variables: { _id: item._id },
                              });
                              onSelect();
                            } catch (err) {
                              if (
                                (err as ApolloError).graphQLErrors &&
                                (err as ApolloError).graphQLErrors.length > 0
                              ) {
                                message.error(
                                  (err as ApolloError).graphQLErrors[0].message,
                                );
                              } else {
                                message.error(
                                  t(
                                    "sorry-we-are-having-a-problem-deleting-the-tenant",
                                  ),
                                );
                              }
                            }
                          },
                          onCancel() {},
                        });
                      }}
                    >
                      <DeleteOutlined /> {t("delete")}
                    </Button>
                  </div>
                </div>
              }
              trigger="click"
              placement="right"
              open={popoverOpen}
              onOpenChange={(visible) => {
                setPopoverOpen(visible);
              }}
            >
              <Button
                style={{ flexShrink: 0 }}
                shape="circle"
                icon={<MoreOutlined />}
                size="small"
                onClick={() => setPopoverOpen(!popoverOpen)}
              />
            </Popover>
          )
        }
      >
        <Space direction="vertical">
          <Text>{`${t("license-allocation-prompt")} ${
            item.licensesAllocated ?? 0
          }/${item.licenseAllocation}`}</Text>
          <Text>{`${t("instance-allocation-prompt")} ${
            item.instancesAllocated ?? 0
          }/${item.instanceAllocation}`}</Text>
        </Space>
      </Card>
    </div>
  );
};

type ViewProps = {
  style: CSSProperties;
  selectedTenant: Tenant | undefined;
  onSelect: (item: Tenant | undefined) => void;
  nameFilter?: string;
};

const View = ({ style, selectedTenant, onSelect, nameFilter }: ViewProps) => {
  const { data, loading } = useQuery(ALL_TENANTS, {
    variables: {
      nameFilter,
    },
  });

  return data && data.allTenants ? (
    <List
      style={style}
      loading={loading}
      dataSource={
        data && data.allTenants
          ? data.allTenants.filter(
              (t: Tenant) => t.name !== CONSTANTS.DEFAULT_TENANT,
            )
          : []
      }
      renderItem={(item: Tenant) => (
        <TenantRow
          item={item}
          selectedTenant={selectedTenant}
          onSelect={(i) => onSelect(i)}
          nameFilter={nameFilter}
        />
      )}
    />
  ) : (
    <></>
  );
};

export default View;
