import { useContext, useState } from "react";
import {
  UserOutlined,
  MailOutlined,
  GroupOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { Card, message, Alert, Typography, Tag } from "antd";
import {
  USER_QUERY,
  UPDATE_USER_DETAILS,
  // ALL_GROUPS,
  ALL_USERS,
  GET_USER,
} from "../../Queries";
import { Formik } from "formik";
import { Form, Input, SubmitButton, Select, Switch } from "formik-antd";
import * as Yup from "yup";
import { SECURITY } from "@bcspi/common";
import UpdatePasswordForm from "./UpdatePasswordForm";
import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { User } from "ui/Types";
import { SystemContext } from "ui/App";
import { useTranslation } from "react-i18next";

const { Text } = Typography;

type UserFormProps = {
  user: User;
};

const UserForm = ({ user }: UserFormProps) => {
  const { t } = useTranslation();

  const Schema = Yup.object({
    name: Yup.string().label(t("name")).required(t("name-is-required")).min(1),
    ...(!user.roles.includes(SECURITY.USER_ROLES.SUPER_USER) && {
      licenseAllocation: Yup.number()
        .label(t("license-allocation"))
        .required(t("license-allocation-is-required"))
        .min(user.profile.resourceUsage.licensesUsed ?? 0)
        .max(
          user.tenant.licenseAllocation -
            (user.profile.resourceUsage.tenantLicensesAllocated ?? 0) +
            user.profile.licenseAllocation,
        ),
      instanceAllocation: Yup.number()
        .label(t("instance-allocation"))
        .required(t("instance-allocation-is-required"))
        .min(user.profile.resourceUsage.instancesUsed ?? 0)
        .max(
          user.tenant.instanceAllocation -
            (user.profile.resourceUsage.tenantInstancesAllocated ?? 0) +
            user.profile.instanceAllocation,
        ),
    }),
  });

  const systemContext = useContext(SystemContext);

  const [updateUserDetails, { loading }] = useMutation(UPDATE_USER_DETAILS, {
    refetchQueries: [
      { query: GET_USER, variables: { _id: user._id } },
      { query: USER_QUERY },
      // { query: ALL_GROUPS },
      { query: ALL_USERS },
    ],
  });

  const [errors, setErrors] = useState<String[]>([]);

  // let opts: JSX.Element[] = [];
  // if (user.accountType === SECURITY.ACCOUNT_TYPES.SYSTEM) {
  //   opts = _.map(SECURITY.getSystemRoles(), (role) => (
  //     <Select.Option key={role.role} value={role.role}>
  //       {role.title}
  //     </Select.Option>
  //   ));
  // }
  // if (user.accountType === SECURITY.ACCOUNT_TYPES.USER) {
  //   opts = _.map(SECURITY.getUserRoles(), (role) => (
  //     <Select.Option key={role.role} value={role.role}>
  //       {role.title}
  //     </Select.Option>
  //   ));
  // }
  return (
    <Formik
      initialValues={{
        name: user.name,
        roles: user.roles,
        username: user.username,
        licenseAllocation: user.profile.licenseAllocation,
        instanceAllocation: user.profile.instanceAllocation,
        tenant: user.tenant.name,
        autoSubscribe: user.profile.autoSubscribe ?? false,
      }}
      validationSchema={Schema}
      enableReinitialize={true}
      onSubmit={async (data) => {
        try {
          setErrors([]);
          await updateUserDetails({ variables: { ...data, userId: user._id } });
          message.success(t("user-has-been-updated"));
        } catch (error) {
          if (error && (error as ApolloError).graphQLErrors) {
            setErrors(
              (error as ApolloError).graphQLErrors.map(
                ({ message: msg }) => msg,
              ),
            );
          }
        }
      }}
    >
      {(formik) => (
        <Form>
          <div style={{}}>
            <Form.Item name="name" style={{ width: 400 }}>
              <Text strong>{t("full-name")}</Text>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Input
                  style={{ marginTop: 4 }}
                  prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                  name="name"
                  placeholder={t("full-name-placeholder")}
                />
                {user.createdBy === "-1" && (
                  <div>
                    <Tag style={{ marginLeft: 8 }} color="green">
                      {t("superuser")}
                    </Tag>
                  </div>
                )}
                {user.accountType === SECURITY.ACCOUNT_TYPES.SYSTEM && (
                  <div>
                    <Tag style={{ marginLeft: 8 }} color="volcano">
                      {t("system")}
                    </Tag>
                  </div>
                )}
              </div>
            </Form.Item>

            <Form.Item name="username" style={{ width: 400 }}>
              <Text strong>{t("email-address-heading")}</Text>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Input
                  style={{ marginTop: 4 }}
                  prefix={<MailOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                  name="username"
                  disabled
                />
              </div>
            </Form.Item>

            <Form.Item
              name="tenant"
              style={{ width: 400 }}
              hidden={
                !systemContext.user?.roles.includes(
                  SECURITY.USER_ROLES.SUPER_USER,
                )
              }
            >
              <Text strong>{t("tenant")}</Text>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Input
                  style={{ marginTop: 4 }}
                  prefix={
                    <GroupOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  name="tenant"
                  disabled
                />
              </div>
            </Form.Item>
            {!user.roles.includes(SECURITY.USER_ROLES.SUPER_USER) && (
              <>
                <Form.Item
                  name="roles"
                  style={{ width: 400 }}
                  hidden={user.roles.includes(SECURITY.USER_ROLES.SUPER_USER)}
                >
                  <Text strong>{t("role")}</Text>
                  <Select
                    name="roles"
                    placeholder={t("select-a-role-placeholder")}
                    optionFilterProp="children"
                    disabled={
                      user.roles.includes(SECURITY.USER_ROLES.SUPER_USER) ||
                      systemContext.user?.roles.includes(
                        SECURITY.USER_ROLES.SUPER_USER,
                      )
                    }
                    popupMatchSelectWidth={false}
                  >
                    <Select.Option
                      key={SECURITY.USER_ROLES.SPI_ADMIN}
                      value={SECURITY.USER_ROLES.SPI_ADMIN}
                    >
                      {SECURITY.ROLE_TITLES[SECURITY.USER_ROLES.SPI_ADMIN]}
                    </Select.Option>
                    <Select.Option
                      key={SECURITY.USER_ROLES.SPI_USER}
                      value={SECURITY.USER_ROLES.SPI_USER}
                    >
                      {SECURITY.ROLE_TITLES[SECURITY.USER_ROLES.SPI_USER]}
                    </Select.Option>
                  </Select>
                </Form.Item>
                {user.accountType === SECURITY.ACCOUNT_TYPES.SYSTEM && (
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Text strong>{t("api-key")}</Text>
                    <Text style={{ marginTop: 4, fontSize: 18 }} code>
                      {user.apiKey}
                    </Text>
                  </div>
                )}
                <Form.Item
                  name="licenseAllocation"
                  style={{ width: 400 }}
                  hidden={user.roles.includes(SECURITY.USER_ROLES.SUPER_USER)}
                >
                  <Text strong>{`${t("license-allocation-heading")} (min: ${
                    user.profile.resourceUsage.licensesUsed ?? 0
                  } max: ${
                    user.tenant.licenseAllocation -
                    (user.profile.resourceUsage.tenantLicensesAllocated ?? 0) +
                    user.profile.licenseAllocation
                  })`}</Text>
                  <Input
                    style={{}}
                    type={"number"}
                    min={user.profile.resourceUsage.licensesUsed ?? 0}
                    max={
                      user.tenant.licenseAllocation -
                      (user.profile.resourceUsage.tenantLicensesAllocated ??
                        0) +
                      user.profile.licenseAllocation
                    }
                    name="licenseAllocation"
                    placeholder={t("license-allocation-placeholder")}
                  />
                </Form.Item>
                <Form.Item
                  name="instanceAllocation"
                  style={{ width: 400 }}
                  hidden={user.roles.includes(SECURITY.USER_ROLES.SUPER_USER)}
                >
                  <Text strong>{`${t("instance-allocation-heading")} (min: ${
                    user.profile.resourceUsage.instancesUsed ?? 0
                  } max: ${
                    user.tenant.instanceAllocation -
                    (user.profile.resourceUsage.tenantInstancesAllocated ?? 0) +
                    user.profile.instanceAllocation
                  })`}</Text>
                  <Input
                    style={{}}
                    type={"number"}
                    min={user.profile.resourceUsage.instancesUsed ?? 0}
                    max={
                      user.tenant.instanceAllocation -
                      (user.profile.resourceUsage.tenantInstancesAllocated ??
                        0) +
                      user.profile.instanceAllocation
                    }
                    name="instanceAllocation"
                    placeholder={t("instance-allocation-placeholder")}
                  />
                </Form.Item>
              </>
            )}
            <Form.Item name="autoSubscribe">
              <Text strong>{t("auto-subscribe")}</Text>
              <br />
              <Switch
                name="autoSubscribe"
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
              />
            </Form.Item>
          </div>
          <SubmitButton
            loading={loading}
            disabled={
              formik.isSubmitting ||
              (formik.values.name === user?.name &&
                formik.values.autoSubscribe ===
                  (user?.profile.autoSubscribe ?? false) &&
                (String(formik.values.roles) === user?.roles[0] ||
                  user.roles.includes(SECURITY.USER_ROLES.SUPER_USER)) &&
                formik.values.licenseAllocation ===
                  user?.profile.licenseAllocation &&
                formik.values.instanceAllocation ===
                  user?.profile.instanceAllocation) ||
              !formik.isValid
            }
            style={{ marginTop: 12 }}
          >
            {t("save")}
          </SubmitButton>
          <div>
            {errors.map((msg, i) => (
              <Alert key={i} message={msg} type="error" showIcon />
            ))}
          </div>
        </Form>
      )}
    </Formik>
  );
};

type ViewProps = {
  userId: string | undefined;
};

const View = ({ userId }: ViewProps) => {
  const { t } = useTranslation();

  const { data, loading } = useQuery(GET_USER, {
    variables: { _id: userId },
    skip: !userId,
  });

  if (!userId || loading) {
    return (
      <div>
        <div>{t("select-an-account")}</div>
      </div>
    );
  }

  return data && data.getUser ? (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "stretch",
        alignItems: "stretch",
      }}
    >
      <div style={{ padding: 12 }}>
        <Card size="small">
          <UserForm user={data.getUser} />
        </Card>
      </div>

      <div style={{ marginTop: 12, padding: 12 }}>
        <Card title={t("password")} size="small">
          <UpdatePasswordForm
            userId={userId}
            // buttonTitle="Update Password"
            onComplete={() =>
              message.success(t("the-user-password-was-updated-successfully"))
            }
          />
        </Card>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default View;
