import { Alert, ConfigProvider } from "antd";
import { CONSTANTS } from "@bcspi/common";
import Router from "./routes";
import { USER_QUERY, SYSTEM_QUERY, LOGOUT_USER } from "./Queries";

import LoadingSpinner from "./components/Common/LoadingSpinner";

// import "antd/dist/antd.compact.css";
// import "../css/antd_override.css";
// import "../css/overrides.css";
// import "../css/dnd.css";
import "../css/antd_override.css";
import { SystemInfo, User, UserQuery } from "./Types";
import { useMutation, useQuery } from "@apollo/client";
import React, { Suspense } from "react";
import { getLocale } from "./getLocale";
import { LOCAL_STORAGE_KEYS, LOCAL_STORAGE_VERSION } from "./Constants";
import NavigationHeader from "./components/NavigationHeader";
import InitialisationUI from "./components/InitialisationUI";
import { getThemeConfig } from "./getThemeConfig";

type SystemContextType = {
  systemInfo?: SystemInfo;
  user?: User;
  logout?: () => void;
};
export const SystemContext = React.createContext<SystemContextType>({});

// loading component for suspense fallback
const Loader = () => (
  <div style={{ width: "100vw", height: "100vh" }}>
    <div className="launch-screen">
      <LoadingSpinner />
    </div>
  </div>
);

const App = (): JSX.Element => {
  const {
    error: systemError,
    loading: systemLoading,
    data: systemData,
  } = useQuery(SYSTEM_QUERY, {
    fetchPolicy: "network-only",
  });

  const { client, error, loading, data } = useQuery<UserQuery>(USER_QUERY, {
    fetchPolicy: "network-only",
  });

  const [logout] = useMutation(LOGOUT_USER);

  const storeVersion = localStorage.getItem(LOCAL_STORAGE_KEYS.STORE_VERSION);

  if (!storeVersion || storeVersion !== LOCAL_STORAGE_VERSION)
    localStorage.clear();

  localStorage.setItem(LOCAL_STORAGE_KEYS.STORE_VERSION, LOCAL_STORAGE_VERSION);

  if (systemLoading || loading) {
    return <Loader />;
  }
  if (error || systemError) {
    return (
      <Suspense fallback={<Loader />}>
        <div style={{ width: "100vw", height: "100vh", overflow: "hidden" }}>
          <div className="launch-screen">
            <h3>
              Error Loading Blackchair SaaS Provisioning Interface, maybe the
              server is down. Please try again later.
              <br />
              {error && error.graphQLErrors.length > 0 && (
                <Alert
                  message={
                    systemError
                      ? systemError.graphQLErrors[0].message
                      : error.graphQLErrors[0].message
                  }
                  type="error"
                  showIcon
                />
              )}
            </h3>
          </div>
        </div>
      </Suspense>
    );
  }

  const {
    getSystemInfo: { isInitialised },
  } = systemData;
  if (!isInitialised) {
    return (
      <Suspense fallback={<Loader />}>
        <div
          style={{
            width: "100%",
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#f0f2f5",
          }}
        >
          <NavigationHeader />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: 80,
            }}
          >
            <InitialisationUI
              style={{ width: 500 }}
              onComplete={() => {
                //Go to the login screen
                window.location = CONSTANTS.URL_ROOT;
              }}
            />
          </div>
        </div>
      </Suspense>
    );
  }

  const contextData: SystemContextType = {
    systemInfo: systemData.getSystemInfo,
    user: data?.me,
    logout: async () => {
      window.location.replace(`${CONSTANTS.URL_ROOT}`);
      await logout();
      await client.resetStore();
    },
  };

  return (
    <Suspense fallback={<Loader />}>
      <ConfigProvider
        theme={getThemeConfig(false)}
        locale={getLocale(data?.me?.profile.locale ?? "en-us")}
      >
        <SystemContext.Provider value={contextData}>
          <Router />
        </SystemContext.Provider>
      </ConfigProvider>
    </Suspense>
  );
};

export default App;
