import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";
import { Space, Typography } from "antd";

const { Text } = Typography;

type PasswordRuleProps = {
  text: string;
  pass: boolean;
};

const PasswordRule = (props: PasswordRuleProps): JSX.Element => {
  return (
    <Space>
      {props.pass ? (
        <CheckCircleTwoTone twoToneColor="green" />
      ) : (
        <CloseCircleTwoTone twoToneColor="red" />
      )}
      <Text>{props.text}</Text>
    </Space>
  );
};

export default PasswordRule;
