import { useState } from "react";
import * as Yup from "yup";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Formik } from "formik";
import { Form, Input, SubmitButton } from "formik-antd";
import { FORGOTTEN_PASSWORD, LOGIN_USER, USER_QUERY } from "ui/Queries";
import {
  Card,
  Alert,
  Button,
  Modal,
  Input as AntInput,
  Space,
  Typography,
} from "antd";
import { LoginUserVars } from "../Types";
import { ApolloError, useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import RequestTenantAccountForm from "ui/components/UserAccounts/RequestTenantAccountForm";
import { useLocation, useNavigate } from "react-router-dom";
import { CONSTANTS } from "@bcspi/common";
import PasswordRuleList from "ui/components/Common/PasswordRuleList";

const { success } = Modal;
const { Text } = Typography;

type RequestTenantAccountModalProps = {
  visible: boolean;
  onCancel: () => void;
  onComplete: () => void;
};

const Login: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const [forgottenPassword] = useMutation(FORGOTTEN_PASSWORD);

  const [showForgottenModal, setShowForgottenModal] = useState(false);
  const [usernameForgotten, setUsernameForgotten] = useState("");

  const [showRequestTenantAccountModal, setShowRequestTenantAccountModal] =
    useState(false);

  const [loginError, setLoginError] = useState<ApolloError | null>(null);

  const [passwordExpired, setPasswordExpired] = useState(false);

  const [login, { loading }] = useMutation<{ login: LoginUserVars }>(
    LOGIN_USER,
  );

  const initialValues = {
    email: "",
    password: "",
    newPassword: undefined,
  };

  const schema = Yup.object({
    email: Yup.string()
      .label(t("email-address"))
      .email()
      .required(t("email-is-required"))
      .min(5),
    password: Yup.string().required(t("password-is-required")).min(3), //ensure some kind of minimum but don't tell user here what the rules are
    ...(passwordExpired && {
      newPassword: Yup.string()
        .label(t("password"))
        .required(t("password-is-required"))
        .min(8, t("minimum-length-is-8-characters"))
        .matches(
          /^.*((?=.*[A-Z]){1}).*$/,
          t("password-must-contain-at-least-one-uppercase-character"),
        )
        .matches(
          /^.*((?=.*[a-z]){1}).*$/,
          t("password-must-contain-at-least-one-lowercase-character"),
        )
        .matches(
          /^.*(?=.*\d).*$/,
          t("password-must-contain-at-least-one-number"),
        )
        .matches(
          /^.*((?=.*[!@#$£%^&*()\-_=+{};:,<.>]){1}).*$/,
          t("password-must-contain-at-least-one-special-case-character"),
        ),
      confirmNewPassword: Yup.string()
        .label(t("password"))
        .required(t("password-is-required"))
        .nullable()
        .oneOf([Yup.ref("newPassword"), null], t("passwords-must-match")),
    }),
  });

  const forgottenPasswordOkClick = () => {
    forgottenPassword({
      variables: { username: usernameForgotten },
      onError: (error) => {
        if (error && (error as ApolloError).graphQLErrors) {
          setLoginError(error as ApolloError);
        }
      },
    });
    setUsernameForgotten("");
    setShowForgottenModal(false);
  };

  const RequestTenantAccountModal = ({
    visible,
    onCancel,
    onComplete,
  }: RequestTenantAccountModalProps) => {
    return (
      <Modal
        title={t("request-new-account")}
        open={visible}
        destroyOnClose={true}
        onCancel={() => onCancel()}
        footer={null}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
            justifyContent: "center",
          }}
        >
          <RequestTenantAccountForm
            onComplete={() => onComplete()}
            onCancel={() => onCancel()}
          />
        </div>
      </Modal>
    );
  };

  return (
    <>
      <div style={{ minWidth: 420 }}>
        <Space direction="vertical" style={{ width: "100%" }}>
          {location.state === "session-expired" && (
            <Alert message={t("session-expired")} type="warning" showIcon />
          )}
          <Card title={t("login-title")} hoverable>
            <Formik
              initialValues={initialValues}
              validationSchema={schema}
              onSubmit={async (values, actions) => {
                setLoginError(null);

                try {
                  await login({
                    variables: {
                      username: values.email,
                      password: values.password,
                      newPassword: values.newPassword,
                    },
                    refetchQueries: [{ query: USER_QUERY }],
                  });
                  navigate(location || CONSTANTS.URL_ROOT, { replace: true });
                } catch (error) {
                  if (
                    error &&
                    (error as ApolloError).graphQLErrors.length > 0 &&
                    (error as ApolloError).graphQLErrors[0].message ===
                      "Password expired"
                  )
                    setPasswordExpired(true);
                  setLoginError(error as ApolloError);
                }

                actions.setSubmitting(false);
              }}
            >
              {(formik) => (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Form>
                    <Form.Item name="email">
                      <Input
                        autoFocus
                        name="email"
                        placeholder={t("email-address-placeholder")}
                        prefix={<UserOutlined />}
                      />
                    </Form.Item>
                    <Form.Item name="password">
                      <Input.Password
                        name="password"
                        placeholder={t("password-placeholder")}
                        prefix={<LockOutlined />}
                      />
                    </Form.Item>
                    <Form.Item name="newPassword" hidden={!passwordExpired}>
                      <Input.Password
                        name="newPassword"
                        prefix={<LockOutlined />}
                        placeholder={t("new-password-placeholder")}
                      />
                    </Form.Item>
                    <Form.Item
                      name="confirmNewPassword"
                      hidden={!passwordExpired}
                    >
                      <Input.Password
                        name="confirmNewPassword"
                        prefix={<LockOutlined />}
                        placeholder={t("confirm-new-password-placeholder")}
                      />
                    </Form.Item>
                    <div
                      style={{ width: "100%", marginBottom: "15px" }}
                      hidden={!passwordExpired}
                    >
                      <PasswordRuleList
                        password={formik.values.newPassword}
                        showWhenEmpty
                      />
                    </div>
                    <SubmitButton
                      disabled={formik.isSubmitting || !formik.isValid}
                      loading={loading}
                    >
                      {t("log-in")}
                    </SubmitButton>
                  </Form>
                  {loginError && (
                    <Alert
                      style={{ marginTop: 12 }}
                      message={t("error")}
                      description={
                        loginError && loginError.graphQLErrors.length > 0
                          ? loginError.graphQLErrors[0].message
                          : t("there-has-been-an-error-logging-in")
                      }
                      type="error"
                    />
                  )}
                </div>
              )}
            </Formik>
          </Card>
        </Space>
      </div>
      <Button
        name="forgottenPassword"
        type={"link"}
        onClick={() => {
          setShowForgottenModal(true);
        }}
      >
        {t("forgotten-password-question")}
      </Button>
      <Button
        style={{ marginTop: 10 }}
        name="requestAccount"
        //type={"link"}
        onClick={() => {
          setShowRequestTenantAccountModal(true);
        }}
      >
        {t("request-saas-account")}
      </Button>
      <Modal
        title={t("reset-your-password")}
        open={showForgottenModal}
        destroyOnClose
        onOk={forgottenPasswordOkClick}
        okText={t("reset")}
        okButtonProps={{ disabled: usernameForgotten.length === 0 }}
        onCancel={() => {
          setUsernameForgotten("");
          setShowForgottenModal(false);
        }}
      >
        <Space direction="vertical">
          <AntInput
            autoFocus
            name="email"
            placeholder={t("email-address-placeholder")}
            prefix={<UserOutlined />}
            onChange={(e) => setUsernameForgotten(e.target.value)}
          ></AntInput>
          <Text>
            {t(
              "you-will-receive-an-email-containing-your-new-password-message-next",
            )}
          </Text>
        </Space>
      </Modal>
      <RequestTenantAccountModal
        visible={showRequestTenantAccountModal}
        onComplete={() => {
          success({
            title: t("request-saas-account"),
            content: (
              <Space direction="vertical">
                <Text>{t("a-new-saas-account-has-been-requested")}</Text>
                <Text>
                  {t("once-this-has-been-verified-and-approved-message")}
                </Text>
              </Space>
            ),
          });
        }}
        onCancel={() => setShowRequestTenantAccountModal(false)}
      />
    </>
  );
};

export default Login;
