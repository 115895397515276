import CreateUserForm from "../UserAccounts/CreateUserForm";
import { Card } from "antd";
import { CSSProperties } from "react";
import { useTranslation } from "react-i18next";

type ViewProps = {
  style: CSSProperties;
  onComplete: () => void;
};

const View = ({ style, onComplete }: ViewProps) => {
  const { t } = useTranslation();
  return (
    <div style={style}>
      <Card title={t("please-create-a-super-administrator-account")}>
        <CreateUserForm
          onComplete={() => onComplete()}
          onCancel={() => {}}
          isInitial={true}
        />
      </Card>
    </div>
  );
};

export default View;
