import { useRef, useState } from "react";
import { Form, Input, SubmitButton } from "formik-antd";
import { Formik } from "formik";
import { MailOutlined, UserOutlined } from "@ant-design/icons";
import {
  Button,
  Alert,
  Divider,
  Modal,
  Input as AntInput,
  Space,
  Typography,
} from "antd";
import {
  FORGOTTEN_PASSWORD,
  REQUEST_TENANT_ACCOUNT,
  USER_EXISTS,
} from "../../Queries";
import { ApolloError, useMutation, useQuery } from "@apollo/client";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";

const { Text } = Typography;

type ViewProps = {
  onCancel: () => void;
  onComplete: () => void;
};

const View = ({ onComplete, onCancel }: ViewProps) => {
  const { t } = useTranslation();

  const [username, setUsername] = useState("");

  const [requestTenantAccount] = useMutation(REQUEST_TENANT_ACCOUNT, {
    onCompleted: () => {
      onComplete();
    },
    onError: (error) => {
      if (error && (error as ApolloError).graphQLErrors) {
        setErrors(
          (error as ApolloError).graphQLErrors.map(({ message: msg }) => msg),
        );
      }
    },
  });

  const { data } = useQuery(USER_EXISTS, {
    variables: { username },
  });

  const Schema = Yup.object({
    fullName: Yup.string()
      .label(t("name"))
      .required(t("name-is-required"))
      .min(1),
    emailAddress: Yup.string()
      .label(t("email-address"))
      .email(t("please-enter-a-valid-email-address"))
      .required(t("email-address-is-required"))
      .min(5)
      .test("username", t("this-email-address-is-already-registered"), () => {
        setShowForgottenLink(data?.userExists);
        return !data?.userExists ?? true;
      }),
    companyName: Yup.string()
      .label(t("company-name"))
      .required(t("company-name-is-required"))
      .min(1),
    jobTitle: Yup.string()
      .label(t("job-title"))
      .required(t("job-title-is-required"))
      .min(1),
    purchaseOrderNumber: Yup.string()
      .label(t("purchase-order-number"))
      .required(t("purchase-order-number-is-required"))
      .min(1),
    licenseCoverage: Yup.number()
      .label(t("license-converage"))
      .required(t("license-coverage-is-required"))
      .min(1),
    instanceLimit: Yup.number()
      .label(t("instance-limit"))
      .required(t("instance-limit-is-required"))
      .min(1),
  });

  const [forgottenPassword] = useMutation(FORGOTTEN_PASSWORD);

  const [showForgottenLink, setShowForgottenLink] = useState(false);
  const [showForgottenModal, setShowForgottenModal] = useState(false);
  const [usernameForgotten, setUsernameForgotten] = useState("");

  const forgottenPasswordOkClick = () => {
    forgottenPassword({
      variables: { username: usernameForgotten },
      onError: (error) => {
        if (error && (error as ApolloError).graphQLErrors) {
          setErrors(
            (error as ApolloError).graphQLErrors.map(({ message: msg }) => msg),
          );
        }
      },
    });
    setUsernameForgotten("");
    setShowForgottenModal(false);
  };

  const initialValues = useRef({
    fullName: "",
    emailAddress: "",
    companyName: "",
    jobTitle: "",
    purchaseOrderNumber: "",
    licenseCoverage: 1,
    instanceLimit: 1,
  });

  const [errors, setErrors] = useState<String[]>([]);

  return (
    <>
      <Formik
        validateOnMount
        initialValues={initialValues.current}
        validationSchema={Schema}
        onSubmit={async (d, actions) => {
          setErrors([]);
          await requestTenantAccount({ variables: { ...d } });
          actions.setSubmitting(false);
        }}
      >
        {(formik) => (
          <Form>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Form.Item name="fullName">
                <Text strong>{t("full-name")}</Text>
                <Input
                  autoFocus
                  name="fullName"
                  prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                  placeholder={t("full-name-placeholder")}
                />
              </Form.Item>
              <Form.Item name="emailAddress">
                <Text strong>{t("email-address")}</Text>
                <Input
                  style={{}}
                  name="emailAddress"
                  prefix={<MailOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                  placeholder={t("email-address-placeholder")}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </Form.Item>
              <Form.Item name="companyName">
                <Text strong>{t("company-name")}</Text>
                <Input
                  style={{}}
                  name="companyName"
                  placeholder={t("company-name-placeholder")}
                />
              </Form.Item>
              <Form.Item name="jobTitle">
                <Text strong>{t("job-title")}</Text>
                <Input
                  style={{}}
                  name="jobTitle"
                  placeholder={t("job-title-placeholder")}
                />
              </Form.Item>
              <Form.Item name="purchaseOrderNumber">
                <Text strong>{t("purchase-order-number")}</Text>
                <Input
                  style={{}}
                  name="purchaseOrderNumber"
                  placeholder={t("purchase-order-number-placeholder")}
                />
              </Form.Item>
              <Form.Item name="licenseCoverage">
                <Text strong>{t("license-coverage")}</Text>
                <Input
                  style={{}}
                  name="licenseCoverage"
                  type={"number"}
                  min={1}
                  placeholder={t("license-coverage-placeholder")}
                  onChange={(e) =>
                    formik.setFieldValue(
                      "licenseCoverage",
                      parseInt(e.target.value),
                    )
                  }
                />
              </Form.Item>
              <Form.Item name="instanceLimit">
                <Text strong>{t("instance-limit")}</Text>
                <Input
                  style={{}}
                  name="instanceLimit"
                  type={"number"}
                  min={1}
                  placeholder={t("instance-limit-placeholder")}
                  onChange={(e) =>
                    formik.setFieldValue(
                      "instanceLimit",
                      parseInt(e.target.value),
                    )
                  }
                />
              </Form.Item>
              {errors.length > 0 && (
                <div style={{ marginTop: 12 }}>
                  {errors.map((message, i) => (
                    <Alert key={i} message={message} type="error" showIcon />
                  ))}
                </div>
              )}
              <Divider />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Button style={{}} onClick={() => onCancel()}>
                  {t("cancel")}
                </Button>
                <SubmitButton
                  loading={formik.isSubmitting}
                  style={{}}
                  disabled={formik.isSubmitting || !formik.isValid}
                >
                  {t("request-account")}
                </SubmitButton>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <Button
        name="forgottenPassword"
        type={"link"}
        onClick={() => {
          setShowForgottenModal(true);
        }}
        hidden={!showForgottenLink}
      >
        {t("forgotten-password-question")}
      </Button>
      <Modal
        title={t("reset-your-password") + "xxx"}
        open={showForgottenModal}
        onOk={forgottenPasswordOkClick}
        okText={t("reset")}
        okButtonProps={{ disabled: usernameForgotten.length === 0 }}
        onCancel={() => {
          setUsernameForgotten("");
          setShowForgottenModal(false);
        }}
      >
        <Space direction="vertical">
          <AntInput
            autoFocus
            name="email"
            placeholder={t("email-address")}
            prefix={<UserOutlined />}
            onChange={(e) => setUsernameForgotten(e.target.value)}
          ></AntInput>
          <Text>
            {t(
              "you-will-receive-an-email-containing-your-new-password-message-next",
            )}
          </Text>
        </Space>
      </Modal>
    </>
  );
};

export default View;
