import { useState } from "react";
import { PlusOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Input, Modal, Typography } from "antd";
import { SizeMe } from "react-sizeme";

import CreateTenantForm from "./CreateTenantForm";
import TenantsList from "./TenantsList";
import TenantDetails from "./TenantDetails";
import { Tenant } from "ui/Types";
import { useTranslation } from "react-i18next";

const { Title } = Typography;

type NewTenantModalProps = {
  visible: boolean;
  onCancel: () => void;
  onComplete: () => void;
};

type SizeMeType = {
  size: {
    width: number;
    height: number;
  };
};

const NewTenantModal = ({
  visible,
  onCancel,
  onComplete,
}: NewTenantModalProps) => {
  const { t } = useTranslation();
  return (
    <Modal
      title={t("new-tenant")}
      open={visible}
      destroyOnClose={true}
      onCancel={() => onCancel()}
      footer={null}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
          justifyContent: "center",
        }}
      >
        <CreateTenantForm
          // refetchOnComplete={true}
          onComplete={() => onComplete()}
          onCancel={() => onCancel()}
        />
      </div>
    </Modal>
  );
};
const View = () => {
  const { t } = useTranslation();

  const [selectedTenant, setSelectedTenant] = useState<Tenant>();
  const [tenantsModalVisible, setTenantsModalVisible] = useState(false);
  const [nameFilter, setNameFilter] = useState<undefined | string>();
  return (
    <>
      <SizeMe monitorHeight={true}>
        {({ size }: SizeMeType) => (
          <div
            style={{
              display: "flex",
              width: "100%",
              height: "100%",
              backgroundColor: "#ffffff",
              overflow: "hidden",
              position: "relative",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: Math.floor(size.width / 4),
                height: Math.floor(size.height),
                backgroundColor: "#ffffff",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: 4,
                  bottom: 4,
                  left: 4,
                  right: 0,
                  border: "1px solid rgb(235, 235, 235)",
                  borderRadius: 4,
                  padding: "8px 8px 8px 12px",
                  overflow: "auto",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "5px",
                  }}
                >
                  <Input
                    value={nameFilter}
                    style={{ width: 250, marginLeft: "2px" }}
                    placeholder={t("filter-placeholder")}
                    allowClear
                    onChange={(e) => setNameFilter(e.target.value)}
                  />
                  <Button
                    size="small"
                    icon={<PlusOutlined />}
                    onClick={() => {
                      setTenantsModalVisible(true);
                    }}
                  />
                </div>
                <div style={{ marginTop: 8 }}>
                  <TenantsList
                    style={{
                      height: size.height - 72,
                      width: Math.floor(size.width / 4) - 24,
                    }}
                    selectedTenant={selectedTenant}
                    onSelect={(tenant: Tenant | undefined) => {
                      setSelectedTenant(tenant);
                    }}
                    nameFilter={nameFilter}
                  />
                </div>
              </div>
            </div>

            <div
              style={{
                position: "absolute",
                top: 0,
                left: Math.floor(size.width / 4),
                width: Math.floor((3 * size.width) / 4),
                height: Math.floor(size.height),
                backgroundColor: "#ffffff",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: 4,
                  bottom: 4,
                  left: 4,
                  right: 4,
                  border: "1px solid rgb(235, 235, 235)",
                  borderRadius: 4,
                  padding: "8px 8px 8px 12px",
                  overflow: "auto",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Title level={4}>
                    <EditOutlined />
                    <span style={{ marginLeft: 12 }}>{t("details")}</span>
                  </Title>
                </div>
                <div style={{ marginTop: 8 }}>
                  <TenantDetails
                    // height={size.height - 72}
                    // width={Math.floor(size.width / 4) - 24}
                    tenantId={selectedTenant?._id}
                    //usersModalVisible={usersModalVisible}
                    // onSelect={(group) => {
                    //   //setUsersModalVisible(false);
                    // }}
                    // onClose={() => {
                    //   // setUsersModalVisible(false);
                    // }}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </SizeMe>
      <NewTenantModal
        visible={tenantsModalVisible}
        onComplete={() => setTenantsModalVisible(false)}
        onCancel={() => setTenantsModalVisible(false)}
      />
    </>
  );
};

export default View;
