import { UserOutlined, WarningOutlined } from "@ant-design/icons";
import { Alert, Button, Space } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import { useTranslation } from "react-i18next";
import { CONSTANTS } from "@bcspi/common";
import UserAccounts from "ui/components/UserAccounts";
import ResourceUsage from "./ResourceUsage";
import { newlineText } from "ui/components/Utils";

const { ErrorBoundary } = Alert;

const View: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: 8,
        backgroundColor: CONSTANTS.COLOURS.BG_OFF_WHITE,
        overflow: "hidden",
        width: "100%",
        height: "100%",
      }}
    >
      <PageHeader
        title={t("users")}
        style={{
          border: "1px solid rgb(235, 237, 240)",
          backgroundColor: "#ffffff",
        }}
        avatar={{
          icon: <UserOutlined />,
          style: { backgroundColor: CONSTANTS.COLOURS.TEAL },
        }}
        subTitle={<ResourceUsage />}
      />

      <div style={{ flex: 1, paddingTop: 12 }}>
        <ErrorBoundary
          message={
            <Space wrap>
              <WarningOutlined style={{ fontSize: 20 }} />
              <div style={{ fontSize: "large" }}>{t("error-message-main")}</div>
            </Space>
          }
          description={
            <Space direction="vertical">
              <Space wrap>
                <div style={{ fontSize: "medium" }}>
                  {newlineText(t("error-description-main"))}
                </div>
                {/* <SmileOutlined spin style={{ fontSize: 20 }} /> */}
              </Space>
              <Button
                type="primary"
                onClick={() => {
                  localStorage.clear();
                  location.reload();
                }}
              >
                {t("clear-local-cache")}
              </Button>
            </Space>
          }
        >
          <UserAccounts />
        </ErrorBoundary>
      </div>
    </div>
  );
};

export default View;
