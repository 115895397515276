import {
  CloudServerOutlined,
  DeleteOutlined,
  DownOutlined,
  LockOutlined,
} from "@ant-design/icons";
import {
  Tooltip,
  Button,
  Progress,
  Space,
  Modal,
  message,
  Card,
  Typography,
  Dropdown,
  Row,
  Col,
  Divider,
} from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Instance, InstanceVpn } from "ui/Types";
import { CONSTANTS } from "@bcspi/common";
import dayjs from "dayjs";
import VpnList from "./VpnList";
import EditVpnForm from "./EditVpnForm";
import { getAppLinks, getInstanceStatus, getLogoText } from "../Utils";
import { OperationVariables } from "@apollo/client";

const { Text } = Typography;

type ViewProps = {
  instance: Instance;
  toggleNotifications: (variables: OperationVariables) => void;
  onDeleteInstance: (instance: Instance) => void;
};

type VpnListState = {
  selected?: InstanceVpn;
  action: "add" | "edit" | "";
  instanceId: string;
};

const View = ({
  instance,
  toggleNotifications,
  onDeleteInstance,
}: ViewProps) => {
  const { t } = useTranslation();

  const [showEditVpn, setShowEditVpn] = useState<VpnListState>({
    action: "",
    instanceId: "",
  });

  const onClickAction = (s: string): void => {
    switch (s) {
      case "addVpn":
        setShowEditVpn({
          action: "add",
          instanceId: instance._id,
        });
        break;
      case "delete":
        onDeleteInstance(instance);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Card
        title={
          <Space>
            <CloudServerOutlined />
            {instance.name}
          </Space>
        }
        key={instance._id}
        style={{
          marginBottom: "10px",
          border: `1px solid ${CONSTANTS.COLOURS.BORDER}`,
        }}
        extra={getInstanceStatus(instance, toggleNotifications).jsx}
        size="small"
      >
        <Row>
          <Col span={8}>
            <Space
              direction="vertical"
              style={{
                height: "100%",
                justifyContent: "center",
              }}
            >
              <Space>
                {`${t("tenant")}:`}
                <Text strong>{instance.tenant.name}</Text>
              </Space>
              <Space>
                {`${t("description")}: `}
                <Text strong>{instance.description}</Text>
              </Space>
              <Space>
                {`${t("license-allocation-heading")}: `}
                <Text strong>{instance.licenseAllocation}</Text>
              </Space>
              <Space>
                {`${t("admin-username")}: `}
                <Text strong>{instance.bcAdminUsername}</Text>
              </Space>
            </Space>
          </Col>
          <Col span={8}>
            <Space
              direction="vertical"
              style={{
                height: "100%",
                width: "100%",
                justifyContent: "center",
              }}
            >
              {(instance.buildProgress ?? 0) < 100 && (
                <Space>
                  {(instance.queuePosition ?? -1) > -1
                    ? `${t("queue-position-prompt")} ${
                        (instance.queuePosition ?? -1) + 1
                      }`
                    : `${t("estimate-prompt")} ${
                        dayjs(instance.buildEstimatedRemain ?? 0).format(
                          "mm:ss",
                        ) ?? 0
                      }`}
                  <div style={{ width: 100 }}>
                    <Progress
                      percent={instance.buildProgress ?? 0}
                      size="small"
                      status="active"
                    />
                  </div>
                </Space>
              )}
              {instance.status === CONSTANTS.BUILD_STATE.COMPLETED &&
                instance.buildResult ===
                  CONSTANTS.JENKINS_BUILD_RESULT.SUCCESS && (
                  <VpnList record={instance} />
                )}
              {instance.deviceInfo.length > 0 && (
                <Space direction="vertical">
                  {`${t("plugins")}:`}
                  {instance.deviceInfo.map((d) => (
                    <Space key={d.deviceId}>
                      <Text strong>{d.name}</Text>
                      <Text>({getLogoText(d.pluginId)})</Text>
                    </Space>
                  ))}
                </Space>
              )}
            </Space>
          </Col>
          <Col span={8}>{getAppLinks(instance)}</Col>
        </Row>
        <Divider style={{ margin: "0px" }} />
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "end",
          }}
        >
          <Dropdown
            key="actionMenu"
            menu={{
              onClick: (info) => onClickAction(info.key.toString()),
              items: [
                {
                  key: "addVpn",
                  icon: <LockOutlined />,
                  disabled:
                    instance.status !== CONSTANTS.BUILD_STATE.COMPLETED ||
                    instance.buildResult !==
                      CONSTANTS.JENKINS_BUILD_RESULT.SUCCESS ||
                    (instance.vpn &&
                      instance.vpn.length > 0 &&
                      instance.vpn[0].buildResult !==
                        CONSTANTS.JENKINS_BUILD_RESULT.FAILURE),
                  label: (
                    <Tooltip placement="left" title={t("add-vpn")}>
                      <span>{t("add-vpn")}</span>
                    </Tooltip>
                  ),
                },
                {
                  key: "delete",
                  icon: <DeleteOutlined />,
                  danger: true,
                  disabled: [
                    CONSTANTS.BUILD_STATE.QUEUED,
                    CONSTANTS.BUILD_STATE.BUILDING,
                  ].includes(instance.status),
                  label: (
                    <Tooltip
                      placement="left"
                      title={
                        instance.status === CONSTANTS.BUILD_STATE.COMPLETED ||
                        instance.status === CONSTANTS.BUILD_STATE.DELETING
                          ? t("remove")
                          : t("instance-cannot-be-removed-while-building")
                      }
                    >
                      <span>{t("delete")}</span>
                    </Tooltip>
                  ),
                },
              ],
            }}
          >
            <Button icon={<DownOutlined />} type={"link"}>
              {t("action")}
            </Button>
          </Dropdown>
        </div>
      </Card>
      {showEditVpn.action !== "" && (
        <Modal
          title={
            showEditVpn.action === "add"
              ? t("add-vpn")
              : `${t("edit-vpn")} - ${showEditVpn.selected?.name}`
          }
          open={true}
          destroyOnClose={true}
          maskClosable={false}
          onCancel={() => setShowEditVpn({ action: "", instanceId: "" })}
          footer={null}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "stretch",
              justifyContent: "center",
            }}
          >
            <EditVpnForm
              record={showEditVpn.selected}
              action={showEditVpn.action}
              instanceId={showEditVpn.instanceId}
              onComplete={(success) => {
                if (success) message.success(t("vpn-saved-successfully"));
                else message.error(t("an-error-occurred-while-saving-the-vpn"));
                setShowEditVpn({ action: "", instanceId: "" });
              }}
              onCancel={() => setShowEditVpn({ action: "", instanceId: "" })}
            />
          </div>
        </Modal>
      )}
    </>
  );
};

export default View;
