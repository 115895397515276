import { CSSProperties, useState } from "react";
import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { ALL_USERS, DELETE_USER, USER_QUERY } from "../../Queries";
import { DeleteOutlined, MoreOutlined } from "@ant-design/icons";
import {
  Card,
  List,
  Button,
  Modal,
  message,
  Popover,
  Typography,
  Tag,
  Space,
} from "antd";
import { SECURITY } from "@bcspi/common";
import { User } from "ui/Types";
import { useTranslation } from "react-i18next";

const { Text } = Typography;
const confirm = Modal.confirm;

type UserRowProps = {
  item: User;
  selectedAccount: User | undefined;
  onSelect: (item?: User) => void;
  nameFilter?: string;
};

const UserRow = ({
  item,
  selectedAccount,
  onSelect,
  nameFilter,
}: UserRowProps) => {
  const { t } = useTranslation();

  const [deleteUser] = useMutation(DELETE_USER, {
    refetchQueries: [
      {
        query: ALL_USERS,
        variables: {
          nameFilter,
        },
      },
      { query: USER_QUERY },
      // { query: ALL_GROUPS },
    ],
  });
  const [popoverOpen, setPopoverOpen] = useState(false);

  let cellBackgroundColour = "#ffffff";
  const isSelected =
    selectedAccount && selectedAccount._id === item._id ? true : false;
  if (isSelected) {
    cellBackgroundColour = "rgba(186, 231, 255, 0.5)";
  }
  return (
    <div key={item._id} style={{ padding: 2, width: "100%" }}>
      <Card
        size="small"
        hoverable
        style={{ backgroundColor: cellBackgroundColour }}
        onClick={() => {
          onSelect(item);
        }}
        title={
          <Space wrap>
            <Text strong>{item.name}</Text>
            {item.createdBy === "-1" && (
              <Tag style={{ marginLeft: 8 }} color="green">
                {t("superuser")}
              </Tag>
            )}
            {item.accountType === SECURITY.ACCOUNT_TYPES.SYSTEM && (
              <Tag style={{ marginLeft: 8 }} color="volcano">
                {t("system")}
              </Tag>
            )}{" "}
            {item.roles.includes(SECURITY.USER_ROLES.SPI_ADMIN) && (
              <Tag style={{ marginLeft: 8 }} color="purple">
                {t("admin")}
              </Tag>
            )}
            {item.roles.includes(SECURITY.USER_ROLES.SPI_USER) && (
              <Tag style={{ marginLeft: 8 }} color="blue">
                {t("operator")}
              </Tag>
            )}
          </Space>
        }
        extra={
          item.createdBy !== "-1" && (
            <Popover
              content={
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{}}>
                    <Button
                      type="link"
                      style={{ color: "#fd7273" }}
                      onClick={(e) => {
                        e.preventDefault();

                        //Check they want do do that
                        confirm({
                          title: t("delete"),
                          content: t(
                            "are-you-sure-you-want-to-delete-this-account-question",
                          ),
                          async onOk() {
                            setPopoverOpen(false);
                            try {
                              await deleteUser({
                                variables: { userId: item._id },
                              });
                              onSelect();
                            } catch (err) {
                              if (
                                (err as ApolloError).graphQLErrors &&
                                (err as ApolloError).graphQLErrors.length > 0
                              ) {
                                message.error(
                                  (err as ApolloError).graphQLErrors[0].message,
                                );
                              } else {
                                message.error(
                                  t(
                                    "sorry-we-are-having-a-problem-deleting-the-account",
                                  ),
                                );
                              }
                            }
                          },
                          onCancel() {},
                        });
                      }}
                    >
                      <DeleteOutlined /> {t("delete")}
                    </Button>
                  </div>
                </div>
              }
              trigger="click"
              placement="right"
              open={popoverOpen}
              onOpenChange={(visible) => {
                setPopoverOpen(visible);
              }}
            >
              <Button
                style={{ flexShrink: 0 }}
                shape="circle"
                icon={<MoreOutlined />}
                size="small"
                onClick={() => setPopoverOpen(!popoverOpen)}
              />
            </Popover>
          )
        }
      >
        <Space direction="vertical">
          <Text>{item.username}</Text>
          <Text>{`${t("license-allocation-used-prompt")} ${
            item.profile.resourceUsage.licensesUsed
          }/${item.profile.licenseAllocation}`}</Text>
          <Text>{`${t("instance-allocation-used-prompt")} ${
            item.profile.resourceUsage.instancesUsed ?? 0
          }/${item.profile.instanceAllocation}`}</Text>
        </Space>
      </Card>
    </div>
  );
};

type ViewProps = {
  style: CSSProperties;
  selectedAccount: User | undefined;
  onSelect: (item: User | undefined) => void;
  nameFilter?: string;
};

const View = ({ style, selectedAccount, onSelect, nameFilter }: ViewProps) => {
  const { data, loading } = useQuery(ALL_USERS, {
    fetchPolicy: "network-only",
    variables: {
      nameFilter,
    },
  });

  return data && data.allUsers ? (
    <List
      style={style}
      loading={loading}
      dataSource={data && data.allUsers ? data.allUsers : []}
      renderItem={(item: User) => (
        <UserRow
          item={item}
          selectedAccount={selectedAccount}
          onSelect={(i) => onSelect(i)}
          nameFilter={nameFilter}
        />
      )}
    />
  ) : (
    <></>
  );
};

export default View;
