import { useRef, useState } from "react";
import { Form, Input, Select, SubmitButton } from "formik-antd";
import { Formik } from "formik";
import { LockOutlined, UploadOutlined, UserOutlined } from "@ant-design/icons";
import {
  Button,
  Alert,
  Divider,
  Typography,
  // Modal,
  // message,
  Upload,
} from "antd";
import {
  // DELETE_INSTANCE_VPN,
  // RESTART_INSTANCE_VPN,
  SAVE_INSTANCE_VPN,
} from "../../Queries";
import { ApolloError, useMutation } from "@apollo/client";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { InstanceVpn } from "ui/Types";
// import { logError } from "../Utils";
import { RcFile } from "antd/lib/upload";

const { Text } = Typography;
// const { confirm } = Modal;

type ViewProps = {
  onCancel: () => void;
  onComplete: (success: boolean) => void;
  record: InstanceVpn | undefined;
  action: "add" | "edit";
  instanceId: string;
};

const View = ({
  onComplete,
  onCancel,
  record,
  action,
  instanceId,
}: ViewProps) => {
  const { t } = useTranslation();

  const [selectedType, setSelectedType] = useState<string>("avaya");

  const [saveInstanceVpn, { loading: loadingSave }] =
    useMutation(SAVE_INSTANCE_VPN);
  // const [deleteInstanceVpn, { loading: loadingDelete }] = useMutation(
  //   DELETE_INSTANCE_VPN,
  //   {
  //     variables: { _id: instanceId, name: record?.name },
  //     onCompleted: () => {
  //       message.success(t("vpn-removed-successfully"));
  //       onCancel();
  //     },
  //     onError: (err) => {
  //       message.success(t("error-occurred-removing-vpn"));
  //       logError(err as Error);
  //     },
  //   }
  // );

  // const [restartInstanceVpn, { loading: loadingRestart }] = useMutation(
  //   RESTART_INSTANCE_VPN,
  //   {
  //     variables: { _id: instanceId, name: record?.name },
  //     onCompleted: () => {
  //       message.success(t("vpn-restarted-successfully"));
  //       onCancel();
  //     },
  //     onError: (err) => {
  //       message.success(t("error-occurred-restarting-vpn"));
  //       logError(err as Error);
  //     },
  //   }
  // );

  // const onDeleteVpn = () => {
  //   confirm({
  //     title: t("restart"),
  //     content: t("remove-this-vpn-question"),
  //     onOk() {
  //       deleteInstanceVpn();
  //     },
  //   });
  // };

  // const onRestartVpn = () => {
  //   confirm({
  //     title: t("remove"),
  //     content: t("restart-this-vpn-question"),
  //     onOk() {
  //       restartInstanceVpn();
  //     },
  //   });
  // };

  const Schema = Yup.object({
    name: Yup.string().label(t("name")).required(t("name-is-required")).min(1),
    // username: Yup.string()
    //   .label(t("username"))
    //   .required(t("username-is-required"))
    //   .min(1),
    // password: Yup.string()
    //   .label(t("password"))
    //   .required(t("password-is-required"))
    //   .min(1),
  });

  const initialValues = useRef({
    name: action === "edit" && record ? record.name : "",
    type: "avaya",
    usernameVpn: action === "edit" && record ? record.username : "",
    passwordVpn: "",
    file: "",
    crtFile: "",
    keyFile: "",
  });

  const [errors, setErrors] = useState<String[]>([]);

  const [fileState, setFileState] = useState<RcFile>();
  const [crtFileState, setCrtFileState] = useState<RcFile>();
  const [keyFileState, setKeyFileState] = useState<RcFile>();

  return (
    <>
      <Formik
        validateOnMount
        initialValues={initialValues.current}
        validationSchema={Schema}
        onSubmit={async (d) => {
          setErrors([]);
          try {
            const res = await saveInstanceVpn({
              variables: {
                _id: instanceId,
                name: d.name,
                type: selectedType,
                username: selectedType === "avaya" ? d.usernameVpn : "",
                password: selectedType === "avaya" ? d.passwordVpn : "",
                file: fileState,
                crtFile: crtFileState,
                keyFile: keyFileState,
              },
            });
            onComplete(res.data.saveInstanceVpn);
          } catch (error) {
            if (error && (error as ApolloError).graphQLErrors) {
              setErrors(
                (error as ApolloError).graphQLErrors.map(
                  ({ message: msg }) => msg,
                ),
              );
            }
          }
        }}
      >
        {(formik) => (
          <Form>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Form.Item name="type">
                <Text strong>{t("type")}</Text>
                <Select
                  name="type"
                  onChange={(e) => {
                    setSelectedType(e);
                    formik.setFieldValue("usernameVpn", "");
                    formik.setFieldValue("passwordVpn", "");
                  }}
                >
                  <Select.Option key={"avaya"} value={"avaya"}>
                    Avaya
                  </Select.Option>
                  <Select.Option key={"cisco"} value={"cisco"}>
                    Cisco
                  </Select.Option>
                </Select>
              </Form.Item>
              <Form.Item name="name">
                <Text strong>{t("name")}</Text>
                <Input
                  autoFocus
                  name="name"
                  placeholder={t("name-placeholder")}
                  disabled={action === "edit"}
                />
              </Form.Item>
              <Form.Item name="usernameVpn">
                <Text strong>{t("username")}</Text>
                <Input
                  style={{}}
                  name="usernameVpn"
                  prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                  placeholder={t("username-placeholder")}
                  disabled={selectedType !== "avaya"}
                  autoComplete="off"
                />
              </Form.Item>
              <Form.Item name="passwordVpn">
                <Text strong>{t("password")}</Text>
                <Input
                  style={{}}
                  name="passwordVpn"
                  type="password"
                  prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                  placeholder={t("password-placeholder")}
                  disabled={selectedType !== "avaya"}
                  autoComplete="off"
                />
              </Form.Item>
              <Text strong>{"OVPN File"}</Text>
              <Upload
                maxCount={1}
                onRemove={() => setFileState(undefined)}
                beforeUpload={(file) => {
                  setFileState(file);
                  return false;
                }}
              >
                <Button icon={<UploadOutlined />}>Select OVPN File...</Button>
              </Upload>
              <Text strong>{"CRT File"}</Text>
              <Upload
                maxCount={1}
                onRemove={() => setFileState(undefined)}
                beforeUpload={(file) => {
                  setCrtFileState(file);
                  return false;
                }}
                disabled={selectedType !== "cisco"}
              >
                <Button
                  icon={<UploadOutlined />}
                  disabled={selectedType !== "cisco"}
                >
                  Select CRT File...
                </Button>
              </Upload>
              <Text strong>{"KEY File"}</Text>
              <Upload
                maxCount={1}
                onRemove={() => setFileState(undefined)}
                beforeUpload={(file) => {
                  setKeyFileState(file);
                  return false;
                }}
                disabled={selectedType !== "cisco"}
              >
                <Button
                  icon={<UploadOutlined />}
                  disabled={selectedType !== "cisco"}
                >
                  Select KEY File...
                </Button>
              </Upload>
              {errors.length > 0 && (
                <div style={{ marginTop: 12 }}>
                  {errors.map((message, i) => (
                    <Alert key={i} message={message} type="error" showIcon />
                  ))}
                </div>
              )}
              <Divider />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Button
                  style={{ marginRight: "auto" }}
                  onClick={() => onCancel()}
                >
                  {t("cancel")}
                </Button>
                {/* <Button
                  danger
                  style={{ marginRight: "10px" }}
                  onClick={() => onDeleteVpn()}
                  loading={loadingDelete}
                  disabled={loadingDelete}
                  hidden={action === "add"}
                >
                  {t("remove-vpn")}
                </Button>
                <Button
                  style={{ marginRight: "10px" }}
                  onClick={() => onRestartVpn()}
                  loading={loadingRestart}
                  disabled={loadingRestart}
                  hidden={action === "add"}
                >
                  {t("restart-vpn")}
                </Button> */}
                <SubmitButton
                  loading={loadingSave}
                  style={{}}
                  disabled={
                    formik.isSubmitting || !formik.isValid || !fileState
                  }
                >
                  {t("save-vpn")}
                </SubmitButton>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default View;
