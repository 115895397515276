import {
  CloudServerOutlined,
  GroupOutlined,
  UserOutlined,
  VerifiedOutlined,
} from "@ant-design/icons";
import { Card, Col, Row, Space, Statistic, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { Instance, Tenant } from "ui/Types";
import { CONSTANTS, SECURITY } from "@bcspi/common";
import { getInstanceStatus } from "../Utils";
import { useContext } from "react";
import { SystemContext } from "ui/App";

type ViewProps = {
  tenant: Tenant;
  instances: Instance[];
};

const View = ({ tenant, instances }: ViewProps) => {
  const { t } = useTranslation();

  const systemContext = useContext(SystemContext);

  const operator =
    !systemContext.user?.roles.includes(SECURITY.USER_ROLES.SUPER_USER) &&
    !systemContext.user?.roles.includes(SECURITY.USER_ROLES.SPI_ADMIN);

  return (
    <Card
      title={
        <Space>
          <GroupOutlined />
          {tenant.name}
        </Space>
      }
      style={{ border: `1px solid ${CONSTANTS.COLOURS.BORDER}` }}
    >
      <Row key={tenant._id} gutter={16}>
        {!operator && (
          <Col flex={1}>
            <Card
              title={
                <Space>
                  <UserOutlined />
                  {t("users")}
                </Space>
              }
              style={{
                textAlign: "center",
                background: CONSTANTS.COLOURS.BG_OFF_WHITE,
                marginTop: "8px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-around",
                }}
              >
                <Statistic
                  title="Admin"
                  value={tenant.adminCount}
                  valueStyle={{ textAlign: "center" }}
                />
                <Statistic
                  title="Operators"
                  value={tenant.operatorCount}
                  valueStyle={{ textAlign: "center" }}
                />
              </div>
            </Card>
          </Col>
        )}
        <Col flex={1}>
          <Card
            title={
              <Space>
                <VerifiedOutlined />
                {t("licenses")}
              </Space>
            }
            style={{
              textAlign: "center",
              background: CONSTANTS.COLOURS.BG_OFF_WHITE,
              marginTop: "8px",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-around",
              }}
            >
              <Tooltip title={t("summary-license-inuse-tooltip")}>
                <Statistic
                  title="In Use"
                  value={
                    operator
                      ? systemContext.user?.profile.resourceUsage
                          .licensesUsed ?? 0
                      : tenant.licensesInUse ?? 0
                  }
                  valueStyle={{ textAlign: "center" }}
                />
              </Tooltip>
              {!operator && (
                <Tooltip title={t("summary-license-allocated-tooltip")}>
                  <Statistic
                    title="Allocated"
                    value={tenant.licensesAllocated ?? 0}
                    valueStyle={{ textAlign: "center" }}
                  />
                </Tooltip>
              )}
              <Tooltip
                title={
                  operator
                    ? t("summary-license-allocation-operator-tooltip")
                    : t("summary-license-allocation-tooltip")
                }
              >
                <Statistic
                  title="Allocation"
                  value={
                    operator
                      ? systemContext.user?.profile.licenseAllocation ?? 0
                      : tenant.licenseAllocation
                  }
                  valueStyle={{ textAlign: "center" }}
                />
              </Tooltip>
            </div>
          </Card>
        </Col>
        <Col flex={1}>
          <Card
            title={
              <Space>
                <CloudServerOutlined />
                {t("instances")}
              </Space>
            }
            style={{
              textAlign: "center",
              background: CONSTANTS.COLOURS.BG_OFF_WHITE,
              marginTop: "8px",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-around",
              }}
            >
              <Tooltip title={t("summary-instance-inuse-tooltip")}>
                <Statistic
                  title="In Use"
                  value={
                    operator
                      ? systemContext.user?.profile.resourceUsage
                          .instancesUsed ?? 0
                      : tenant.instancesInUse ?? 0
                  }
                  valueStyle={{ textAlign: "center" }}
                />
              </Tooltip>
              {!operator && (
                <Tooltip title={t("summary-instance-allocated-tooltip")}>
                  <Statistic
                    title="Allocated"
                    value={tenant.instancesAllocated ?? 0}
                    valueStyle={{ textAlign: "center" }}
                  />
                </Tooltip>
              )}
              <Tooltip
                title={
                  operator
                    ? t("summary-instance-allocation-operator-tooltip")
                    : t("summary-instance-allocation-tooltip")
                }
              >
                <Statistic
                  title="Allocation"
                  value={
                    operator
                      ? systemContext.user?.profile.instanceAllocation ?? 0
                      : tenant.instanceAllocation
                  }
                  valueStyle={{ textAlign: "center" }}
                />
              </Tooltip>
            </div>
          </Card>
        </Col>
      </Row>
      {/* {instances.length > 0 && (
        <Row key={`${tenant._id}-instancetitle`}>
          <Col span={24}>Instances</Col>
        </Row>
      )} */}
      {/* <Row style={{ marginTop: "8px", height: "30px" }}>
        <Col
          flex={100}
          style={{
            backgroundColor: CONSTANTS.COLOURS.BG_AMBER,
            borderRight: `2px solid ${CONSTANTS.COLOURS.BORDER}`,
          }}
        >
          <div
            style={{
              height: "100%",
              textAlign: "center",
              lineHeight: "30px",
            }}
          >
            x
          </div>
        </Col>
        <Col
          flex={100}
          style={{
            backgroundColor: CONSTANTS.COLOURS.BG_AMBER,
            borderRight: `2px solid ${CONSTANTS.COLOURS.BORDER}`,
          }}
        >
          <div
            style={{
              height: "100%",
              textAlign: "center",
              lineHeight: "30px",
            }}
          >
            x
          </div>
        </Col>
        <Col
          flex={100}
          style={{
            backgroundColor: CONSTANTS.COLOURS.BG_AMBER,
          }}
        >
          <div
            style={{
              height: "100%",
              textAlign: "center",
              lineHeight: "30px",
            }}
          >
            x
          </div>
        </Col>
        <Col flex={200} style={{ backgroundColor: CONSTANTS.COLOURS.BG_GREEN }}>
          <div
            style={{
              height: "100%",
              textAlign: "center",
              lineHeight: "30px",
            }}
          >
            x
          </div>
        </Col>
        <Col
          flex={500}
          style={{ backgroundColor: CONSTANTS.COLOURS.BG_OFF_WHITE }}
        >
          <div
            style={{
              height: "100%",
              textAlign: "center",
              lineHeight: "30px",
            }}
          >
            unallocated
          </div>
        </Col>
      </Row> */}
      <Row
        key={`${tenant._id}-instances`}
        gutter={16}
        style={{ marginTop: "8px" }}
      >
        {instances.map((i: Instance, idx: number) => {
          const status = getInstanceStatus(i, null);
          return (
            <Col key={idx} flex={1}>
              <Card
                title={i.name}
                style={{
                  textAlign: "center",
                  background: CONSTANTS.COLOURS.BG_OFF_WHITE,
                  marginBottom: "8px",
                }}
                headStyle={{ background: status.bgColour }}
              >
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-around",
                  }}
                >
                  <Statistic
                    title="License Allocation"
                    value={i.licenseAllocation ?? 0}
                    valueStyle={{ textAlign: "center" }}
                  />
                  <Statistic
                    title="Status"
                    valueRender={() => status.jsx}
                    valueStyle={{ textAlign: "center" }}
                  />
                </div>
              </Card>
            </Col>
          );
        })}
      </Row>
    </Card>
  );
};

export default View;
