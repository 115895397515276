import {
  PlusOutlined,
  QuestionCircleOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { Button, Modal, Empty } from "antd";
import { useTranslation } from "react-i18next";
import { Notice, Tenant } from "ui/Types";
import { useMutation, useQuery } from "@apollo/client";
import {
  ALL_NOTICES,
  ALL_TENANTS,
  DELETE_NOTICE,
  GET_DISPLAY_NOTICES,
  SAVE_NOTICE,
  SEND_NOTICE,
  TOGGLE_NOTICE,
} from "ui/Queries";
import { logError } from "../Utils";
import LoadingSpinner from "../Common/LoadingSpinner";
import NoticeCard from "./NoticeCard";
import { CONSTANTS } from "@bcspi/common";

const { confirm } = Modal;

type ViewProps = {
  noticeBanner: boolean;
};

const View = ({ noticeBanner }: ViewProps) => {
  const { t } = useTranslation();

  const refetchQueries = [
    { query: ALL_NOTICES },
    { query: GET_DISPLAY_NOTICES },
  ];

  const { data, loading } = useQuery(ALL_NOTICES);

  const [saveNotice] = useMutation<{ saveNotice: Notice }>(SAVE_NOTICE, {
    refetchQueries: refetchQueries,
  });

  const [toggleNotice] = useMutation<{ toggleNotice: Notice }>(TOGGLE_NOTICE, {
    refetchQueries: refetchQueries,
  });

  const [sendNotice] = useMutation<{ sendNotice: Notice }>(SEND_NOTICE);

  const [deleteNotice] = useMutation<{ deleteNotice: Notice }>(DELETE_NOTICE, {
    refetchQueries: refetchQueries,
  });

  const { data: dataTenants } = useQuery(ALL_TENANTS);

  const onSendNotice = (notice: Notice, tenants: string[]) => {
    const singleTenant = tenants.length === 1 ?? false;
    const tenantStr =
      tenants.length === 0
        ? "All Tenants"
        : !singleTenant
          ? "Multiple Tenants"
          : tenants[0];
    const tenantStrLower =
      tenants.length === 0
        ? "all tenants"
        : !singleTenant
          ? "multiple tenants"
          : tenants[0];

    confirm({
      icon: singleTenant ? <QuestionCircleOutlined /> : <WarningOutlined />,
      title: `${t("send-notice")} - ${tenantStr}`,
      content: t("send-this-notice-question", { tenants: tenantStrLower }),
      async onOk() {
        try {
          await sendNotice({
            variables: {
              _id: notice._id,
            },
          });
        } catch (err) {
          logError(err as Error);
        }
      },
    });
  };

  const onDeleteNotice = (notice: Notice) => {
    confirm({
      title: t("remove-notice"),
      content: t("remove-this-notice-question"),
      async onOk() {
        try {
          await deleteNotice({
            variables: {
              _id: notice._id,
            },
          });
        } catch (err) {
          logError(err as Error);
        }
      },
    });
  };

  const addNew = () => {
    saveNotice({
      variables: {
        content: `${t("notice")} #${data.getAllNotices.length + 1}`,
        active: false,
        style: {},
        tenants: [],
      },
    });
  };

  return (
    <div style={{ marginBottom: "8px" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "end",
          marginBottom: "12px",
        }}
      >
        <Button icon={<PlusOutlined />} onClick={() => addNew()}>
          {t("create-notice")}
        </Button>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: `calc(100vh - ${noticeBanner ? 222 : 182}px)`, //40? + 64 + 8 + 50 + 12 + 28 + 12 + 8
          overflow: "auto",
          marginTop: "12px",
        }}
      >
        {data && data.getAllNotices.length > 0 ? (
          data.getAllNotices.map((n: Notice) => (
            <NoticeCard
              key={n._id}
              notice={n}
              saveNotice={saveNotice}
              toggleNotice={toggleNotice}
              onSendNotice={onSendNotice}
              onDeleteNotice={onDeleteNotice}
              tenants={
                dataTenants && dataTenants.allTenants
                  ? dataTenants.allTenants.filter(
                      (t: Tenant) => t.name !== CONSTANTS.DEFAULT_TENANT,
                    )
                  : []
              }
            />
          ))
        ) : loading ? (
          <LoadingSpinner />
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </div>
    </div>
  );
};

export default View;
