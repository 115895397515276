import gql from "graphql-tag";

/**
 * User
 */

export const LOGIN_USER = gql`
  mutation login($username: String!, $password: String!, $newPassword: String) {
    login(username: $username, password: $password, newPassword: $newPassword)
  }
`;

export const LOGOUT_USER = gql`
  mutation logout {
    logout
  }
`;

export const CREATE_USER = gql`
  mutation createUser(
    $username: String!
    $name: String!
    $tenant: String!
    $accountType: String!
    $roles: [String]
    $licenseAllocation: Int
    $instanceAllocation: Int
    $autoSubscribe: Boolean
  ) {
    createUser(
      username: $username
      name: $name
      tenant: $tenant
      accountType: $accountType
      roles: $roles
      licenseAllocation: $licenseAllocation
      instanceAllocation: $instanceAllocation
      autoSubscribe: $autoSubscribe
    )
  }
`;

export const DELETE_USER = gql`
  mutation deleteUser($userId: ID!) {
    deleteUser(userId: $userId)
  }
`;

export const UPDATE_PASSWORD = gql`
  mutation updatePassword($password: String!) {
    updatePassword(password: $password)
  }
`;

export const UPDATE_USER_PASSWORD = gql`
  mutation updateUserPassword($userId: ID!, $password: String!) {
    updateUserPassword(userId: $userId, password: $password)
  }
`;

export const FORGOTTEN_PASSWORD = gql`
  mutation forgottenPassword($username: String!) {
    forgottenPassword(username: $username)
  }
`;

export const REQUEST_TENANT_ACCOUNT = gql`
  mutation requestTenantAccount(
    $fullName: String!
    $emailAddress: String!
    $companyName: String!
    $jobTitle: String!
    $purchaseOrderNumber: String!
    $licenseCoverage: Int!
    $instanceLimit: Int!
  ) {
    requestTenantAccount(
      fullName: $fullName
      emailAddress: $emailAddress
      companyName: $companyName
      jobTitle: $jobTitle
      purchaseOrderNumber: $purchaseOrderNumber
      licenseCoverage: $licenseCoverage
      instanceLimit: $instanceLimit
    )
  }
`;

export const UPDATE_USER_DETAILS = gql`
  mutation updateUserDetails(
    $userId: ID!
    $name: String!
    $roles: [String]!
    $licenseAllocation: Int!
    $instanceAllocation: Int!
    $autoSubscribe: Boolean!
  ) {
    updateUserDetails(
      userId: $userId
      name: $name
      roles: $roles
      licenseAllocation: $licenseAllocation
      instanceAllocation: $instanceAllocation
      autoSubscribe: $autoSubscribe
    )
  }
`;

export const ALL_USERS = gql`
  query allUsers($nameFilter: String) {
    allUsers(nameFilter: $nameFilter) {
      _id
      name
      username
      createdBy
      roles
      accountType
      tenant {
        _id
        name
        licenseAllocation
        instanceAllocation
      }
      apiKey
      profile {
        locale
        licenseAllocation
        instanceAllocation
        resourceUsage {
          licensesUsed
          instancesUsed
          totalLicenseAllocation
          totalLicensesUsed
          totalInstanceAllocation
          totalInstancesUsed
          tenantLicensesAllocated
          tenantInstancesAllocated
        }
        autoSubscribe
      }
    }
  }
`;

export const USER_QUERY = gql`
  query me {
    me {
      _id
      username
      name
      roles
      tenant {
        _id
        name
        licenseAllocation
        instanceAllocation
      }
      isAdmin
      profile {
        locale
        licenseAllocation
        instanceAllocation
        resourceUsage {
          licensesUsed
          instancesUsed
          totalLicenseAllocation
          totalLicensesUsed
          totalInstanceAllocation
          totalInstancesUsed
          tenantLicensesAllocated
          tenantInstancesAllocated
        }
        autoSubscribe
      }
    }
  }
`;

export const GET_USER = gql`
  query getUser($_id: ID!) {
    getUser(_id: $_id) {
      _id
      username
      name
      roles
      tenant {
        _id
        name
        licenseAllocation
        instanceAllocation
      }
      isAdmin
      profile {
        locale
        licenseAllocation
        instanceAllocation
        resourceUsage {
          licensesUsed
          instancesUsed
          totalLicenseAllocation
          totalLicensesUsed
          totalInstanceAllocation
          totalInstancesUsed
          tenantLicensesAllocated
          tenantInstancesAllocated
        }
        autoSubscribe
      }
    }
  }
`;

export const UPDATE_LOCALE = gql`
  mutation updateLocale($locale: String!) {
    updateLocale(locale: $locale)
  }
`;

export const UPDATE_NAME = gql`
  mutation updateName($name: String!, $autoSubscribe: Boolean!) {
    updateName(name: $name, autoSubscribe: $autoSubscribe)
  }
`;

export const USER_EXISTS = gql`
  query userExists($username: String!) {
    userExists(username: $username)
  }
`;

/**
 * Tenants
 */

export const SAVE_TENANT = gql`
  mutation saveTenant(
    $_id: ID
    $name: String!
    $licenseAllocation: Int!
    $instanceAllocation: Int!
    $supportEmail: String
  ) {
    saveTenant(
      _id: $_id
      name: $name
      licenseAllocation: $licenseAllocation
      instanceAllocation: $instanceAllocation
      supportEmail: $supportEmail
    )
  }
`;

export const DELETE_TENANT = gql`
  mutation deletedeleteTenantUser($_id: ID!) {
    deleteTenant(_id: $_id)
  }
`;

export const ALL_TENANTS = gql`
  query allTenants($nameFilter: String) {
    allTenants(nameFilter: $nameFilter) {
      _id
      name
      enabled
      licenseAllocation
      licensesAllocated
      instanceAllocation
      instancesAllocated
      adminCount
      operatorCount
      licensesInUse
      instancesInUse
      supportEmail
    }
  }
`;

export const TENANT_QUERY = gql`
  query getTenant($_id: ID!) {
    getTenant(_id: $_id) {
      _id
      name
      enabled
      licenseAllocation
      licensesAllocated
      instanceAllocation
      instancesAllocated
      adminCount
      operatorCount
      licensesInUse
      instancesInUse
      supportEmail
    }
  }
`;

export const TENANT_EXISTS = gql`
  query tenantExists($name: String!, $_id: ID) {
    tenantExists(name: $name, _id: $_id)
  }
`;

/**
 * Instances
 */

export const UPDATE_INSTANCE = gql`
  mutation saveServiceInstance(
    $_id: ID
    $name: String!
    $description: String
    $licenseAllocation: Int
    $sessionMaxAgeMin: Int
  ) {
    saveServiceInstance(
      _id: $_id
      name: $name
      description: $description
      licenseAllocation: $licenseAllocation
      sessionMaxAgeMin: $sessionMaxAgeMin
    )
  }
`;

export const DELETE_INSTANCE = gql`
  mutation deleteServiceInstance($_id: ID!) {
    deleteServiceInstance(_id: $_id)
  }
`;

export const ALL_INSTANCES = gql`
  query getServiceInstances(
    $nameFilter: String
    $limit: Int
    $skip: Int
    $sortField: String
    $sortOrder: Int
  ) {
    getServiceInstances(
      nameFilter: $nameFilter
      limit: $limit
      skip: $skip
      sortField: $sortField
      sortOrder: $sortOrder
    ) {
      total
      instances {
        _id
        name
        description
        tenant {
          _id
          name
        }
        licenseAllocation
        bcAdminUrl
        bcAdminUsername
        status
        buildProgress
        buildResult
        queuePosition
        buildEstimatedRemain
        requestCallbackResult
        vpn {
          name
          type
          username
          ovpnFile
          certFile
          keyFile
          status
          buildResult
        }
        deviceInfo {
          deviceId
          name
          pluginId
          services
        }
        appInfo {
          appName
          link
          status
        }
        isWatched
        createdBy
      }
    }
  }
`;

export const INSTANCE_NAME_EXISTS = gql`
  query instanceNameExists($name: String!, $_id: ID) {
    instanceNameExists(name: $name, _id: $_id)
  }
`;

export const SAVE_INSTANCE_VPN = gql`
  mutation saveInstanceVpn(
    $_id: ID!
    $name: String!
    $type: String!
    $username: String
    $password: String
    $file: Upload!
    $crtFile: Upload
    $keyFile: Upload
  ) {
    saveInstanceVpn(
      _id: $_id
      name: $name
      type: $type
      username: $username
      password: $password
      file: $file
      crtFile: $crtFile
      keyFile: $keyFile
    )
  }
`;

export const DELETE_INSTANCE_VPN = gql`
  mutation deleteInstanceVpn($_id: ID!, $name: String!, $type: String!) {
    deleteInstanceVpn(_id: $_id, name: $name, type: $type)
  }
`;

export const RESTART_INSTANCE_VPN = gql`
  mutation restartInstanceVpn($_id: ID!, $name: String!) {
    restartInstanceVpn(_id: $_id, name: $name)
  }
`;

export const TOGGLE_NOTIFICATIONS = gql`
  mutation toggleNotifications($_id: ID!) {
    toggleNotifications(_id: $_id)
  }
`;

/**
 * Notices
 */

export const ALL_NOTICES = gql`
  query getAllNotices {
    getAllNotices {
      _id
      content
      active
      tenants
      style
    }
  }
`;

export const GET_DISPLAY_NOTICES = gql`
  query getDisplayNotices {
    getDisplayNotices {
      content
      style
    }
  }
`;

export const GET_DISPLAY_NOTICE = gql`
  query getDisplayNotice($lastIdx: Int) {
    getDisplayNotice(lastIdx: $lastIdx) {
      idx
      notice {
        content
        style
      }
    }
  }
`;

export const SAVE_NOTICE = gql`
  mutation saveNotice(
    $_id: ID
    $content: String
    $active: Boolean
    $tenants: [String]
    $style: JSON
  ) {
    saveNotice(
      _id: $_id
      content: $content
      active: $active
      tenants: $tenants
      style: $style
    )
  }
`;

export const TOGGLE_NOTICE = gql`
  mutation toggleNotice($_id: ID!) {
    toggleNotice(_id: $_id)
  }
`;

export const SEND_NOTICE = gql`
  mutation sendNotice($_id: ID!) {
    sendNotice(_id: $_id)
  }
`;

export const DELETE_NOTICE = gql`
  mutation deleteNotice($_id: ID!) {
    deleteNotice(_id: $_id)
  }
`;

/**
 * System
 */

export const SYSTEM_QUERY = gql`
  query systemQuery {
    getSystemInfo {
      isInitialised
      searchEngineURL
      version
      born
      bannerDurationSec
    }
  }
`;

export const COMPLETE_INITIALISATION = gql`
  mutation completeInitialisation {
    completeInitialisation
  }
`;
