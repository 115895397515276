import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

const spinIcon = <LoadingOutlined style={{ fontSize: 32 }} spin />;

const LoadingSpinner = (): JSX.Element => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Spin size="large" indicator={spinIcon} />
      <div
        style={{ position: "absolute", left: 0, right: 0, top: 0, bottom: 0 }}
      />
    </div>
  );
};

export default LoadingSpinner;
