const Diacritics = require("diacritic");

function removeIllegalCharacters(str) {
	let output = str;

	//Remove space and illegal characters
	output = output.replace(/\(.+?\)/g, "");
	output = output.replace(/[^a-z0-9]+/gi, "_");

	return output;
}

function isValidJSON(jsonString) {
	const converted = convertJSON(jsonString);
	return !!converted;
}

function convertJSON(jsonString) {
	try {
		var o = JSON.parse(jsonString);
		if (o && typeof o === "object") {
			return o;
		}
	} catch (e) {}

	return undefined;
}

function toCleanDomainName(str, maxLen) {
	try {
		return Diacritics.clean(str)
			.toLowerCase()
			.replace(/[^a-z0-9]/g, "")
			.substring(0, maxLen);
	} catch (e) {
		return str.substring(0, maxLen);
	}
}

module.exports = {
	removeIllegalCharacters,
	isValidJSON,
	toCleanDomainName,
};
