import { useLocation, useNavigate } from "react-router-dom";

import {
  DashboardOutlined,
  GroupOutlined,
  InfoCircleOutlined,
  UserOutlined,
} from "@ant-design/icons";

import { CONSTANTS, SECURITY } from "@bcspi/common";
import { Layout, Menu } from "antd";

import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { SystemContext } from "ui/App";

const { Sider } = Layout;

const { URL_ROOT } = CONSTANTS;

const NavigationSider: React.FC = () => {
  const systemContext = useContext(SystemContext);
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  let selectedKeys: string[] = [];
  if (location.pathname === URL_ROOT) {
    selectedKeys = ["home"];
  } else if (location.pathname.indexOf("/tenants") > -1) {
    selectedKeys = systemContext.user?.roles.includes(
      SECURITY.USER_ROLES.SUPER_USER,
    )
      ? ["tenants"]
      : ["home"];
  } else if (location.pathname.indexOf("/users") > -1) {
    selectedKeys = ["users"];
  } else if (location.pathname.indexOf("/notices") > -1) {
    selectedKeys = ["notices"];
  }

  const items = [
    {
      key: "home",
      onClick: () => navigate(`${URL_ROOT}`),
      icon: <DashboardOutlined />,
      label: t("dashboard"),
    },
    ...(systemContext.user?.roles.includes(SECURITY.USER_ROLES.SUPER_USER)
      ? [
          {
            key: "tenants",
            onClick: () => navigate(`${URL_ROOT}/tenants`),
            icon: <GroupOutlined />,
            label: t("tenants"),
          },
        ]
      : []),
    ...(systemContext.user?.roles.includes(SECURITY.USER_ROLES.SUPER_USER) ||
    systemContext.user?.roles.includes(SECURITY.USER_ROLES.SPI_ADMIN)
      ? [
          {
            key: "users",
            onClick: () => navigate(`${URL_ROOT}/users`),
            icon: <UserOutlined />,
            label: t("users"),
          },
        ]
      : []),
    ...(systemContext.user?.roles.includes(SECURITY.USER_ROLES.SUPER_USER)
      ? [
          {
            key: "notices",
            onClick: () => navigate(`${URL_ROOT}/notices`),
            icon: <InfoCircleOutlined />,
            label: t("notices"),
          },
        ]
      : []),
  ];

  return (
    <Sider
      collapsible
      defaultCollapsed
      style={{ backgroundColor: CONSTANTS.COLOURS.BCLOGO_BG }}
      // trigger={}
    >
      <Menu
        theme="dark"
        selectedKeys={selectedKeys}
        defaultSelectedKeys={["home"]}
        mode="inline"
        items={items}
        style={{ backgroundColor: CONSTANTS.COLOURS.BCLOGO_BG }}
      />
    </Sider>
  );
};

export default NavigationSider;
