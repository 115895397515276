import { LockOutlined, SyncOutlined, UnlockOutlined } from "@ant-design/icons";
import { Space, Tooltip, Typography } from "antd";
import { CONSTANTS } from "@bcspi/common";
import { useTranslation } from "react-i18next";
import { Instance, InstanceVpn } from "ui/Types";
// import { CSSProperties, useState } from "react";
// import EditVpnForm from "./EditVpnForm";

const { Text } = Typography;

type VpnListProps = {
  record: Instance;
};

// type VpnListState = {
//   selected?: InstanceVpn;
//   action: "add" | "edit" | "";
// };

const VpnList = (props: VpnListProps): JSX.Element => {
  const { t } = useTranslation();

  // const [showEditVpn, setShowEditVpn] = useState<VpnListState>({ action: "" });

  // const [styleClose, setStyleClose] = useState<CSSProperties>({
  //   color: CONSTANTS.COLOURS.TEAL,
  //   visibility: "hidden",
  //   opacity: 0,
  //   transition: "visibility 0.1s, opacity 0.3s linear",
  // });

  return (
    // only single VPN supported at this time
    <>
      <Space
        direction="vertical"
        size={0}
        style={{ height: "100%", justifyContent: "center" }}
      >
        {props.record.vpn &&
          props.record.vpn
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((vpn: InstanceVpn, idx: number) => {
              return (
                <Space direction={"vertical"} key={idx} size={0}>
                  <Space>
                    <Tooltip title={vpn.buildResult}>
                      {vpn.buildResult ===
                      CONSTANTS.JENKINS_BUILD_RESULT.SUCCESS ? (
                        <LockOutlined
                          style={{ color: CONSTANTS.COLOURS.TEAL }}
                        />
                      ) : (
                        <UnlockOutlined
                          style={{ color: CONSTANTS.COLOURS.DANGER_RED }}
                        />
                      )}
                    </Tooltip>
                    {vpn.name}
                    {[
                      CONSTANTS.BUILD_STATE.QUEUED,
                      CONSTANTS.BUILD_STATE.BUILDING,
                    ].includes(vpn.status) && (
                      <Tooltip title={vpn.status.toUpperCase()}>
                        <SyncOutlined
                          spin
                          style={{ color: CONSTANTS.COLOURS.TEAL }}
                        />
                      </Tooltip>
                    )}
                    {/* <Tooltip title={t("edit-vpn")}>
                    <Button
                      type="link"
                      icon={<EditOutlined />}
                      onClick={() => {
                        setShowEditVpn({ selected: vpn, action: "edit" });
                      }}
                    />
                  </Tooltip> */}
                  </Space>
                  {vpn.type === "avaya" && (
                    <Text style={{ fontSize: "smaller" }}>
                      {`${t("username-prompt")} ${vpn.username}`}
                    </Text>
                  )}
                  <Text style={{ fontSize: "smaller" }}>
                    {`${t("ovpn-file-prompt")} ${vpn.ovpnFile}`}
                  </Text>
                  {vpn.type === "cisco" && (
                    <Text style={{ fontSize: "smaller" }}>
                      {`${t("cert-file-prompt")} ${vpn.certFile}`}
                    </Text>
                  )}
                  {vpn.type === "cisco" && (
                    <Text style={{ fontSize: "smaller" }}>{`${t(
                      "key-file-prompt",
                    )} ${vpn.keyFile}`}</Text>
                  )}
                </Space>
              );
            })}
        {/* {!props.record.vpn || props.record.vpn.length === 0 ? (
          <div
            onMouseEnter={() => {
              setStyleClose({
                color: CONSTANTS.COLOURS.TEAL,
                visibility: "visible",
                opacity: 1,
                transition: "visibility 0.1s, opacity 0.3s linear",
              });
            }}
            onMouseLeave={() => {
              setStyleClose({
                color: CONSTANTS.COLOURS.TEAL,
                visibility: "hidden",
                opacity: 0,
                transition: "visibility 0.1s, opacity 0.3s linear",
              });
            }}
          >
            <Tooltip title={t("add-vpn")}>
              <Button
                type="link"
                style={styleClose}
                icon={
                  <PlusCircleOutlined
                    style={{ color: CONSTANTS.COLOURS.TEAL }}
                    onClick={() => {
                      setShowEditVpn({ action: "add" });
                    }}
                  />
                }
              />
            </Tooltip>
          </div>
        ) : null} */}
      </Space>
    </>
  );
};

export default VpnList;
