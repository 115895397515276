import { CopyOutlined } from "@ant-design/icons";
import { CONSTANTS } from "@bcspi/common";
import { Button, message, Space, Tooltip, Typography } from "antd";
import CopyToClipboard from "react-copy-to-clipboard";
import { useTranslation } from "react-i18next";
import { AppInfo, Instance } from "ui/Types";
import { getStatusIconCss } from "../Utils";

const { Link } = Typography;

type AppLinksProps = {
  instance: Instance;
};

const AppLinks = ({ instance }: AppLinksProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Space direction="vertical" size={0} style={{ width: "100%" }}>
      {instance.appInfo.map((app: AppInfo, idx: number) => {
        return (
          <Space key={idx} style={{ width: "100%", justifyContent: "end" }}>
            <Link href={app.link} target="_blank">
              {app.appName}
            </Link>
            <Tooltip title={t("copy-link-to-clipboard")} placement="left">
              <CopyToClipboard
                text={app.link}
                onCopy={() => message.info(t("copied"))}
              >
                <Button
                  type="link"
                  icon={<CopyOutlined />}
                  hidden={
                    instance.buildResult !==
                    CONSTANTS.JENKINS_BUILD_RESULT.SUCCESS
                  }
                />
              </CopyToClipboard>
            </Tooltip>
            <Tooltip title={app.status ? t("up") : t("down")}>
              <div style={getStatusIconCss(app.status, 16)}></div>
            </Tooltip>
          </Space>
        );
      })}
    </Space>
  );
};

export default AppLinks;
