import React, { useContext } from "react";
import { Card, Button } from "antd";
import UpdatePasswordForm from "../components/Administration/Common/UpdatePasswordForm";
import { SystemContext } from "ui/App";
import { useNavigate } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
// import LocaleForm from "ui/components/Administration/Common/LocaleForm";
import UserForm from "ui/components/Administration/Common/UserForm";

const Profile: React.FC = () => {
  const { t } = useTranslation();

  const systemContext = useContext(SystemContext);
  const navigate = useNavigate();

  const user = systemContext.user;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "stretch",
        alignItems: "stretch",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          backgroundColor: "#ffffff",
          padding: 12,
        }}
      >
        <Button shape="circle" onClick={() => navigate(-1)}>
          <LeftOutlined />
        </Button>
        <span style={{ marginLeft: 8 }}>
          <h3 style={{ margin: 0 }}>{t("profile")}</h3>
        </span>
      </div>
      <div style={{ marginTop: 12, padding: 12 }}>
        <Card title={t("user-details")} size="small">
          <UserForm user={user} />
        </Card>
      </div>

      <div style={{ marginTop: 12, padding: 12 }}>
        <Card title={t("password")} size="small">
          <UpdatePasswordForm
            userId={null}
            buttonTitle={t("update-password")}
          />
        </Card>
      </div>

      {/* <div style={{ marginTop: 12, padding: 12 }}>
        <Card title={t("language")} size="small">
          <LocaleForm user={user} />
        </Card>
      </div> */}
    </div>
  );
};

export default Profile;
