module.exports = {
	ROLE_TITLES: {
		"user:admin:superuser": "Admin Super User",
		"user:spi:admin": "Service Administrator",
		"user:spi:user": "Service Operator",
	},
	USER_ROLES: {
		SUPER_USER: "user:admin:superuser", //Initial installation user and full super user,
		SPI_ADMIN: "user:spi:admin",
		SPI_USER: "user:spi:user",
	},
	SYSTEM_ROLES: {
		SPI_API: "system:spi:api",
	},
};
