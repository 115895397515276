import { useState } from "react";
import { Form, Input, SubmitButton } from "formik-antd";
import { Formik } from "formik";
import { LockOutlined } from "@ant-design/icons";
import { Alert, Col, message, Row } from "antd";
import { UPDATE_PASSWORD } from "../../../Queries";
import { ApolloError, useMutation } from "@apollo/client";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import PasswordRuleList from "ui/components/Common/PasswordRuleList";

type ViewProps = {
  userId: string | null;
  buttonTitle: string;
  onComplete?: () => void;
};

interface FormValues {
  password: string;
  confirm: string;
}

const View = ({
  userId,
  buttonTitle = i18next.t("save-password"),
  onComplete,
}: ViewProps): JSX.Element => {
  const { t } = useTranslation();

  const Schema = Yup.object({
    password: Yup.string()
      .label(t("password"))
      .required(t("password-is-required"))
      .min(8, t("minimum-length-is-8-characters"))
      .matches(
        /^.*((?=.*[A-Z]){1}).*$/,
        t("password-must-contain-at-least-one-uppercase-character"),
      )
      .matches(
        /^.*((?=.*[a-z]){1}).*$/,
        t("password-must-contain-at-least-one-lowercase-character"),
      )
      .matches(/^.*(?=.*\d).*$/, t("password-must-contain-at-least-one-number"))
      .matches(
        /^.*((?=.*[!@#$£%^&*()\-_=+{};:,<.>]){1}).*$/,
        t("password-must-contain-at-least-one-special-case-character"),
      ),
    confirm: Yup.string()
      .label(t("password"))
      .required(t("password-is-required"))
      .nullable()
      .oneOf([Yup.ref("password"), null], t("passwords-must-match")),
  });

  const [updatePassword, { loading }] = useMutation(UPDATE_PASSWORD, {
    onCompleted: () =>
      message.success(t("the-user-password-was-updated-successfully")),
  });
  const [errors, setErrors] = useState<String[]>([]);

  const initialValues: FormValues = { password: "", confirm: "" };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Schema}
      onSubmit={async (data) => {
        setErrors([]);
        try {
          await updatePassword({
            variables: { userId, password: data.password },
          });
          onComplete && onComplete();
        } catch (error) {
          if (error && (error as ApolloError).graphQLErrors) {
            setErrors(
              (error as ApolloError).graphQLErrors.map(
                ({ message: msg }) => msg,
              ),
            );
          }
        }
      }}
    >
      {(formik) => (
        <Form>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item name="password">
                <Input
                  style={{}}
                  name="password"
                  prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                  type="password"
                  placeholder={t("password-placeholder")}
                />
              </Form.Item>
              <Form.Item name="confirm">
                <Input
                  style={{}}
                  name="confirm"
                  prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                  type="password"
                  placeholder={t("confirm-password-placeholder")}
                />
              </Form.Item>
              {errors.length > 0 && (
                <div style={{ marginTop: 12 }}>
                  {errors.map((msg, i) => (
                    <Alert key={i} message={msg} type="error" showIcon />
                  ))}
                </div>
              )}
              <SubmitButton
                disabled={
                  formik.isSubmitting ||
                  formik.values.password === "" ||
                  formik.values.confirm === "" ||
                  !formik.isValid
                }
                loading={loading}
                style={{}}
              >
                {buttonTitle}
              </SubmitButton>
            </Col>
            <Col>
              <PasswordRuleList password={formik.values.password} />
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default View;
