import { CONSTANTS } from "@bcspi/common";

export const getColour = ({
  colour,
  isDarkMode,
}: {
  colour?: string;
  isDarkMode: boolean;
}): string =>
  CONSTANTS.COLOURS[colour ?? "PRIMARY"][isDarkMode ? 0 : 1] ??
  CONSTANTS.COLOURS[colour ?? "PRIMARY"];
