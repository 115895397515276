import { ReactNode, useContext } from "react";
import { SystemContext } from "ui/App";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import NavigationHeader from "../components/NavigationHeader";

import Login from "./Login";
import Profile from "./Profile";
import NavigationSider from "../components/NavigationSider";
import { SECURITY, CONSTANTS } from "@bcspi/common";
import UserAccounts from "./UserAccounts";
import Tenants from "./Tenants";
import ServiceInstances from "./ServiceInstances";
import { useTranslation } from "react-i18next";
import Notices from "./Notices";
import { GET_DISPLAY_NOTICES } from "ui/Queries";
import { useQuery } from "@apollo/client";
import { Notice } from "ui/Types";
import NoticeBanner from "ui/components/Common/NoticeBanner";
import { Layout } from "antd";

const { Content } = Layout;

const { URL_ROOT } = CONSTANTS;

type StandardLayoutProps = {
  showSider?: boolean;
  enabled?: boolean;
  children?: ReactNode;
  noticeData?: Notice[] | undefined;
};

const StandardLayout = ({
  showSider = true,
  enabled = true,
  children,
  noticeData,
}: StandardLayoutProps) => {
  const systemContext = useContext(SystemContext);
  return (
    <Layout
      style={{
        overflow: "hidden",
        height: "100vh",
        width: "100vw",
      }}
    >
      {systemContext.user &&
        systemContext.user._id &&
        noticeData &&
        noticeData.length > 0 && (
          <NoticeBanner
            notices={noticeData}
            duration={
              (systemContext.systemInfo?.bannerDurationSec ?? 60) * 1000
            }
          />
        )}
      <NavigationHeader />
      <Layout>
        {showSider && <NavigationSider />}
        {enabled && (
          <Content>
            <>{children}</>
          </Content>
        )}
      </Layout>
    </Layout>
  );
};

const View: React.FC = () => {
  const { t } = useTranslation();

  const systemContext = useContext(SystemContext);

  const { data: noticeData } = useQuery(GET_DISPLAY_NOTICES, {
    // fetchPolicy: "network-only",
    pollInterval: 30000,
    skip: !systemContext.systemInfo?.isInitialised || !systemContext.user,
    // notifyOnNetworkStatusChange: true,
  });

  if (!systemContext.systemInfo?.isInitialised) {
    return <div>{t("not-initialised")}</div>;
  }

  if (systemContext.systemInfo.isInitialised) {
    if (!systemContext.user) {
      return (
        <BrowserRouter>
          <div>
            <NavigationHeader />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                paddingTop: 100,
              }}
            >
              <Login />
            </div>
          </div>
        </BrowserRouter>
      );
    }

    if (systemContext.user) {
      const noticeBannerVisible =
        noticeData &&
        noticeData.getDisplayNotices !== null &&
        noticeData.getDisplayNotices.length > 0;

      const notices =
        noticeData && noticeData.getDisplayNotices
          ? noticeData.getDisplayNotices
          : [];

      return (
        <BrowserRouter>
          <Routes>
            <Route
              path={`${URL_ROOT}`}
              element={
                <StandardLayout noticeData={notices}>
                  <ServiceInstances noticeBanner={noticeBannerVisible} />
                </StandardLayout>
              }
            />

            <Route
              path={`${URL_ROOT}/tenants`}
              element={
                systemContext.user.roles.includes(
                  SECURITY.USER_ROLES.SUPER_USER,
                ) ? (
                  <StandardLayout noticeData={notices}>
                    <Tenants />
                  </StandardLayout>
                ) : (
                  <></>
                )
              }
            />

            <Route
              path={`${URL_ROOT}/users`}
              element={
                <StandardLayout noticeData={notices}>
                  <UserAccounts />
                </StandardLayout>
              }
            />

            <Route
              path={`${URL_ROOT}/notices`}
              element={
                <StandardLayout noticeData={notices}>
                  <Notices noticeBanner={noticeBannerVisible} />
                </StandardLayout>
              }
            />

            <Route
              path={`${URL_ROOT}/profile`}
              element={
                <StandardLayout noticeData={notices}>
                  <Profile />
                </StandardLayout>
              }
            />
          </Routes>
        </BrowserRouter>
      );
    }
  }

  return <div>{t("router")}</div>;
};

export default View;
